import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qty-selector',
  templateUrl: './qty-selector.component.html',
  styleUrls: ['./qty-selector.component.scss']
})
export class QtySelectorComponent implements OnInit {

  @Input() Quantity = 0;
  @Input() MinQuantity: number;
  @Input() MaxQuantity: number;
  @Output() QuantityChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  IncrementQuantity() {
    if(this.Quantity + 1 < this.MaxQuantity) {
      this.Quantity++;
    }
    this.QuantityChange.emit(this.Quantity);
  }

  DecrementQuantity() {
    if(this.Quantity - 1 >= this.MinQuantity) {
      this.Quantity--;
    }
    this.QuantityChange.emit(this.Quantity);
  }

}

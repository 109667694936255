<div class="card-list-wrapper">
    <div *ngFor="let card of Cards" class="card-wrapper">
        <div class="card-img">
            <img [src]="card.img ? card.img : '../../../assets/media/defaults/card.jpg'" alt="">
        </div>
        <div class="card-info">
            <div class="card-title">
                {{ card.title }}
            </div>
            <div class="card-description">
                {{ card.description }}
            </div>
            <div class="card-rating">
                <div class="rating">
                    <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Category } from 'src/assets/models/management/menu.model';

@Component({
  selector: 'app-category-filters',
  templateUrl: './category-filters.component.html',
  styleUrls: ['./category-filters.component.scss']
})
export class CategoryFiltersComponent implements OnInit, OnChanges {

  @Input() CategoryType: string;
  public Categories: Category[];
  public SelectedCategory: number;

  constructor(private supplierService: SuppliersService) { }

  async ngOnInit() {
    this.Categories = await this.supplierService.getCategories(this.CategoryType);
  }

  async ngOnChanges(changes) {
    if(changes.CategoryType) {
    this.Categories = await this.supplierService.getCategories(this.CategoryType);
    this.ClearCategory();
    }
  }

  SelectCategory(category: number) {
    this.SelectedCategory = category;
    this.supplierService.SetCategory(category);
  }

  ClearCategory() {
    this.SelectedCategory = null;
    this.supplierService.SetCategory(null);
  }

  SetDefault(event, target) {
    event.target.src = 'assets/media/defaults/card.jpg';
  }
}

import { trigger, transition, style, animate } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Supplier, SupplierParameters } from 'src/assets/models/management/supplier.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'scale(0)' }),
            animate('0.3s ease-out', 
                    style({ transform: 'scale(1)' }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ transform: 'scale(1)' }),
            animate('0.3s ease-in', 
                    style({ transform: 'scale(0)' }))
          ]
        )
      ]
    )
  ]
  
})
export class SuppliersComponent implements OnInit, OnDestroy {

  public CategoryTitle = 'Fornecedores';
  public Suppliers: Supplier[];
  public ShowSupplierModal: boolean;
  public SelectedSupplier: Supplier;
  public Loading = true;
  public DayOfWeek = (new Date().getDay()).toString();
  public SupplierParameters: SupplierParameters;
  public SearchSubscription: Subscription;
  public NumOfCartItems: number;
  public TotalCartPrice: any;
  @ViewChild('supplierWrapper', {static: false}) supplierWrapper: ElementRef;

  constructor(private supplierService: SuppliersService,
    private shoppingCartService: CartService,
    private sessionService: SessionService,
    private navigationService: NavigationService) { }

  async ngOnInit() {
    this.Loading = true;
    this.SearchSubscription = this.supplierService.ParametersChanged.subscribe(async params => {
      this.SupplierParameters = params;
      if(params.city && params.start) {
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 15);
        if(new Date(params.start) > currentDate) {
          await this.GetSuppliers(); 
        } else {
          this.navigationService.NavigateTo({itemGo: ['home']});
          this.supplierService.SetDeliveryHours('');
        }
      } else {
        this.navigationService.NavigateTo({itemGo: ['home']})
      }
    });
    this.SupplierParameters = this.supplierService.Parameters;
    if(this.SupplierParameters.city && this.SupplierParameters.start) {
      await this.GetSuppliers();
      this.supplierWrapper.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    this.NumOfCartItems = this.shoppingCartService.TotalItems;
    this.shoppingCartService.TotalItemsChange.subscribe(value => {
      this.NumOfCartItems = value;
    });

    this.TotalCartPrice = parseFloat(this.shoppingCartService.TotalPrice.toString()).toFixed(2) ;
    this.shoppingCartService.TotalPriceChange.subscribe(value => {
      this.TotalCartPrice = parseFloat(value.toString()).toFixed(2);
    });

  }

  async GetSuppliers() {
    this.Loading = true;
    const response = await this.supplierService.getSuppliers(this.SupplierParameters);
    this.Suppliers = response.sort((a, b) => { return a.availability < b.availability ?  1 : 0 });
    setTimeout(() => {
      this.Loading = false;
    }, 250);
  }

  OpenSupplierModal(supplier: Supplier) {
    // this.SelectedSupplier = supplier;
    // this.ShowSupplierModal = true;
    const goTo = new NavItem();
    goTo.itemGo = ['suppliers', supplier.id.toString()];
    
    this.navigationService.NavigateTo(goTo);
  }

  SetDefault(event, target) {
    event.target.src = 'assets/media/defaults/card.jpg';
  }

  async CheckoutOrder() {
    this.shoppingCartService.CloseShoppingCart();
    const goTo = new NavItem();
    if(this.sessionService.LoggedIn) {
      goTo.itemGo = ['checkout'];
    } else {
      goTo.itemGo = ['login', 'checkout'];
    }
    this.navigationService.NavigateTo(goTo);
  }

  ngOnDestroy() {
    this.SearchSubscription.unsubscribe();
  }

}

<div class="cookie-wrapper">
    <div class="disclaimer">
        <div class="logo">
            <img src="../../../assets/media/img/logo_white.png" alt="">
        </div>
        <div class="info">
            A nossa plataforma utiliza cookies para o seu correto funcionamento. Para aceder é necessário que
            aceite o armazenamento de cookies no seu dispositivo.
        </div>
        <div class="options">
            <div class="option">
                <app-generic-button [Label]="'Não concordo'"></app-generic-button>
            </div>
            <div class="option">
                <app-generic-button [Label]="'Concordo'" [CustomClass]="'orange'" (ClickedChange)="AcceptCookies()"></app-generic-button>
            </div>
        </div>
    </div>
</div>
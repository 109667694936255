<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="limit-form-wrapper">
        <div class="policy-wrapper">
            <h2>POLÍTICA DE COOKIES</h2>

            Este Website recorre ao uso de Cookies para um bom funcionamento e uma gestão correta de informação.

            <h3>O que são Cookies e como funcionam?</h3>

            Cookies são pequenos ficheiros de texto armazenados no seu computador ou dispositivo móvel, através do seu
            navegador
            de internet, que os armazena numa pasta de ficheiros. Cada Cookie armazenado é único, e contém informação
            anónima
            constituída por um identificador, nome da página web e um código alfa numérico.

            Os Cookies recolhem informações genéricas relacionadas com as preferências dos utilizadores, país de
            conexão,
            idioma, dados de sessão, etc. permitindo persistir definições do utilizador. Sempre que voltar a visitar a
            nossa
            página web, as suas definições já estarão carregadas.

            <h3>Por que usamos Cookies?</h3>
            A nossa plataforma utiliza cookies para armazenar a sessão do utilizador (ou a ausência de) nas diferentes
            secções
            da mesma e pedidos feitos ao nosso servidor. Para além disso, utilizamos cookies para memorizar as seleções
            do
            utilizador nos diversos parâmetros da encomenda, como por exemplo: hora de entrega, cidade de entrega, etc.

            <h3>Outros Cookies</h3>
            Para além das cookies internas, poderão ser utilizadas cookies de fontes externas, como o Google Analytics,
            para
            medir a performance do website.

            <h3>Revisão da nossa Política de Cookies</h3>
            Esta Política de Cookies pode ser revista a qualquer momento, de acordo com o nosso critério. Recomendamos
            aos
            utilizadores do nosso website que revejam as Políticas de Cookies periodicamente, com o propósito de ficarem
            informados sobre a nossa gestão das Cookies.
        </div>
    </div>
</div>
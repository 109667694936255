<div class="scheduling-wrapper">
    <div class="scheduling-inner-wrapper" [class.loading]="LoadingCategory">
        <div class="scheduling-time-wrapper">
            <!-- <div class="categories-wrapper">
                <div *ngFor="let category of CategoryOptions" [class.selected]="category.key === Category"
                    class="category-wrapper" (click)="SetSelectedCategory(category.key)">
                    <i class="regular-small-svg"
                        [inlineSVG]="'../../../assets/media/categories/' + category.key + '.svg'"></i>
                    <span>{{ category.value }}</span>
                </div>
            </div> -->
            <div class="scheduling-options-wrapper">
                <div class="scheduling-options">
                    <h1>A sua próxima refeição sem sair de casa!</h1>
                    <div class="dropdown-wrapper" (click)="openPlacePicker()">
                        <i class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/store-buy.svg'"></i>
                        <span *ngIf="!SelectedCityOption" class="dropdown-placeholder">Selecione uma localidade</span>
                        <span *ngIf="SelectedCityOption">{{ SelectedCityOption.city }}</span>
                    </div>
                    <div class="dropdown-wrapper" (click)="openTimePicker()">
                        <i class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/period.svg'"></i>
                        <span *ngIf="!DeliveryTime" class="dropdown-placeholder">Selecione uma hora</span>
                        <span *ngIf="DeliveryTime">{{ DeliveryTime }}</span>
                    </div>
                    <div class="dropdown-wrapper no-p">
                        <button [disabled]="!DeliveryTime" (click)="Search()">Pesquisar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
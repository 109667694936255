import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-box',
  templateUrl: './accordion-box.component.html',
  styleUrls: ['./accordion-box.component.scss']
})
export class AccordionBoxComponent implements OnInit {

  @Input() Title: string;
  @Input() Expanded: boolean;
  @Input() Minimizable = true;
  @Input() Deletable = true;
  @Input() AccordionIndex?: number;
  @Input() AccordionDeleteCommand?: string;
  @Output() OptionsEventSender: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ExpandAccordion() {
    this.Expanded = !this.Expanded;
  }

  DeleteEntry() {
    // const optionToSend = new TopNavItem();
    // optionToSend.id = this.AccordionDeleteCommand;
    // optionToSend.value = this.AccordionIndex;
    // this.OptionsEventSender.emit({eventType: 'option', eventValue: optionToSend});
  }

}
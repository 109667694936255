<div class="user-order-wrapper">
    <div class="user-order-header">
        <div class="user-order-title">
            Refª <span (click)="followOrder(order.order.orderid)">{{ (order.order.ordernumber || '') }}</span>
        </div>
        <div class="status-box" [class.hold]="order.order.orderstatusid === 1"
                [class.preparing]="order.order.orderstatusid === 2 || order.order.orderstatusid === 7"
                [class.ready]="order.order.orderstatusid === 3" [class.delivering]="order.order.orderstatusid === 4"
                [class.finished]="order.order.orderstatusid === 5" [class.canceled]="order.order.orderstatusid === 6">
                {{ Status[order.order.orderstatusid] }}
    </div>
    </div>
    <div class="user-order-information">
        <div class="user-order-row">
            <b>Morada</b>
            {{ order.order.streetname }}, {{ order.order.postcode }} {{ order.order.city }}
        </div>
        <div class="user-order-row total">
            <b>Total </b> {{ order.order.totalprice }}€ ({{ order.order.payment }})
        </div>
        <div class="user-order-action">
            <!-- <app-generic-button [Label]="'Estado da encomenda'" (ClickedChange)="followOrder(order.order.orderid)"></app-generic-button> -->
            <app-generic-button [Label]="'Detalhes do pedido'" (ClickedChange)="openOrderDetails()"></app-generic-button>
        </div>
    </div>
</div>
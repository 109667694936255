<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="policy-wrapper">
        <h2>Ajuda & Questões</h2>
        <br><br>
        <h3>Como cancelar uma encomenda?</h3>
        <p>Podes cancelar a tua encomenda até que a mesma se encontre no estado "Pendente", a partir desse momento, se a tua encomenda já se encontra aceite e/ou em 
            preparação apenas é possivel cancelar através de método telefónico.
        <br> <br> 
        Para cancelar uma encomenda no estado "Pendente",  acede à tua secção de <b>"Encomendas"</b> no Menú pessoal e pressiona o botão de cancelamento. <br><br> Para o método telefónico, no mesmo Menú pressiona o botão de "Ajuda" 
        que iniciará uma chamada com o nosso operador.
        </p>
        <br><br>
        <h3>Não há uma aplicação Android ou iOS?</h3>
        <p>O Pedir Sem Sair dispensa a instalação de software nos vossos smartphones, pelo que não é necessário ocupar espaço com uma aplicação que é utilizada de forma ocasional!</p>
        <br><br>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.scss']
})
export class CartPopupComponent implements OnInit {

  public ShowShoppingCart: boolean;
  public NumOfCartItems: number;
  public TotalCartPrice: any;

  constructor(private shoppingCartService: CartService,
    private navigationService: NavigationService,
    private sessionService: SessionService) {

  }

  ngOnInit() {
 
    this.ShowShoppingCart = this.shoppingCartService.ShowShoppingCart;
    this.shoppingCartService.ShowShoppingCartChange.subscribe(value => {
      this.ShowShoppingCart = value;
    });

    this.NumOfCartItems = this.shoppingCartService.TotalItems;
    this.shoppingCartService.TotalItemsChange.subscribe(value => {
      this.NumOfCartItems = value;
    });

    this.TotalCartPrice = parseFloat(this.shoppingCartService.TotalPrice.toString()).toFixed(2) ;
    this.shoppingCartService.TotalPriceChange.subscribe(value => {
      this.TotalCartPrice = parseFloat(value.toString()).toFixed(2);
    });

  }

  closeCart() {
    this.shoppingCartService.CloseShoppingCart();
  }

  async checkoutOrder() {
    this.shoppingCartService.CloseShoppingCart();
    const goTo = new NavItem();
    if(this.sessionService.LoggedIn) {
      goTo.itemGo = ['checkout'];
    } else {
      goTo.itemGo = ['login', 'checkout'];
    }
    this.navigationService.NavigateTo(goTo);
  }

}

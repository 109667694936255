<div class="topnav-wrapper">
    <!-- <span id="menu-clicker" (click)="OpenMenu()">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
    </span> -->
    <div class="topnav-logo" (click)="GoHome()">
        <img id="desktop" src="../../../assets/media/img/logo_pss.svg" alt="">
        <img id="mobile" src="../../../assets/media/img/logo_pss.svg" alt="">
    </div>
    <div class="topnav-options">
        <div *ngIf="ShowCart" class="topnav-option cart" (click)="OpenCart()">
            <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/shopping.svg'"></span>
            <div class="shopping-cart-amount">
                {{ TotalCartItems }}
            </div>
        </div>
        <div *ngIf="LoggedIn" class="topnav-option" (click)="OpenMenu()">
            <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/avatar.svg'"></span>
        </div>
        <div *ngIf="!LoggedIn" class="topnav-option" (click)="Login()">
            <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/unlock.svg'"></span>
        </div>
    </div>
</div>
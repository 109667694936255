<div *ngIf="OpenModal" class="popup-background-wrapper" (click)="CloseModal();">
    <div class="popup-body" [@ModalAnimation] (click)="$event.stopPropagation();">
        <span class="popup-close" (click)="CloseModal()" [inlineSVG]="'../../../../assets/media/img/close.svg'"></span>
        <h2><ng-content select="[slot=title]"></ng-content></h2>
        <div class="popup-body-scroll">
            <ng-content select="[slot=body]"></ng-content>
        </div>
        <div class="popup-actions">
            <ng-content select="[slot=actions]"></ng-content>
        </div>
    </div>
</div>
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Menu } from 'src/assets/models/management/menu.model';
import { Supplier } from 'src/assets/models/management/supplier.model';

@Component({
  selector: 'app-supplier-menus',
  templateUrl: './supplier-menus.component.html',
  styleUrls: ['./supplier-menus.component.scss']
})
export class SupplierMenusComponent implements OnInit, OnChanges {

  @Input() Supplier: Supplier;
  public Loading = true
  public Menus: Menu[];
  public MenuByTypes = {};

  constructor(private supplierService: SuppliersService) { }

  async ngOnInit() {
    await this.BuildMenuByTypes();
    this.Loading = false;
  }

  async ngOnChanges(changes) {
    if (changes.Supplier && changes.Supplier.previousValue) {
      this.Loading = true;
      await this.BuildMenuByTypes();
      this.Loading = false;
    }
  }

  async BuildMenuByTypes() {
    this.MenuByTypes = {};
    this.Menus = await this.supplierService.getSupplierMenu(this.Supplier.id, this.supplierService.Parameters);

    this.Menus.forEach(menu => {
      if(!this.MenuByTypes[menu.menutypename]) {
        this.MenuByTypes[menu.menutypename] = [];
      }

      this.MenuByTypes[menu.menutypename].push(menu);
    });
  }

}

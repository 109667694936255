import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SupplierParameters } from 'src/assets/models/management/supplier.model';
import { popupAnimation } from './animations/animations.animations';
import { LocationService } from './services/location.service';
import { ModalService } from './services/modal.service';
import { SessionService } from './services/session.service';
import { SuppliersService } from './services/suppliers.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [popupAnimation]
})
export class AppComponent {
  title = 'pandev-fe';
  AcceptedCookies: boolean;
  Loaded: boolean;
  showPopup: boolean;

  constructor(private locationService: LocationService,
    private route: Router,
    private supplierService: SuppliersService, 
    private sessionService: SessionService,
    private modalService: ModalService) {
      this.modalService.activeModal.subscribe(value => {
        this.showPopup = value ? true : false;
      });
  }

  async ngOnInit() {

    this.AcceptedCookies = localStorage.getItem('PD-COOKIES') === '1' ? true : false;
    await this.locationService.BuildCityList();
    await this.locationService.BuildCategoryTypes();
    const params = new SupplierParameters();
    params.category_type = sessionStorage.getItem('PD-CAT') || 'restauration';
    params.city = sessionStorage.getItem('PD-CITY') ? parseInt(sessionStorage.getItem('PD-CITY')) : undefined;
    const time = sessionStorage.getItem('PD-TIME') ? sessionStorage.getItem('PD-TIME') : undefined;
    
    if(time) {
      const times = time.split('-');
      params.start = times[0];
      params.end = times[1];
    }
    this.supplierService.ChangeParameters(params);

    this.route.events.subscribe(value => {
      if(value instanceof NavigationEnd) {
        window.scrollTo(0, 0);
          gtag('config', 'G-CHWTXX8ZW3', {
            'page_title': document.title + ' ' + value.urlAfterRedirects,
            'page_path': value.urlAfterRedirects
          });
      }
    })
    this.Loaded = true;
  }

  closeDialog() {
    this.modalService.closeDialog();
  }

}

<div class="location-modal-wrapper">
    <div class="location-wrapper  custom-scrollbar">
        <div class="location-span-wrapper">
            <span *ngFor="let city of options" [class.selected]="selectedCity && selectedCity.id === city.id" 
            (click)="selectedCity = city">
                {{ city.city }}
            </span>
        </div>
    </div>
    <div class="location-options-wrapper">
        <app-generic-button [Label]="'Cancelar'" (ClickedChange)="close()" [CustomClass]="'cancel'">
        </app-generic-button>
        <app-generic-button [Label]="'Continuar'" (ClickedChange)="closeDialog()" [Disabled]="!selectedCity">
        </app-generic-button>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserAddress, UserAddressList } from 'src/assets/models/management/user.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { AddressPopupComponent } from '../address-popup/address-popup.component';
import { ConfirmationModalComponent } from '../popup-modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.component.html',
  styleUrls: ['./user-addresses.component.scss']
})
export class UserAddressesComponent implements OnInit {

  public isLoading = true;
  public cityOptions: DropdownItem[];
  public addresses: UserAddressList;
  public SelectedAddress: UserAddress;
  public Saving: boolean;
  public OpenAddressPopup: boolean;

  constructor(private sessionService: SessionService,
    private locationService: LocationService,
    private router: Router,
    private modalService: ModalService,
    private toastService: ToastService) { }

  async ngOnInit() {
    this.isLoading = true;
    this.cityOptions = this.locationService.BuildCities();
    this.addresses = await this.sessionService.GetUserAddresses();
    this.isLoading = false;
  }

  async SaveChanges() {
    this.Saving = true;
    try {
      await this.sessionService.saveUserInfo(this.addresses);
      this.addresses = await this.sessionService.GetUserAddresses();
      this.toastService.AddSuccessToast('Definições de morada atualizadas com sucesso.');
    } catch (error) {
      if (error.status === 403) {
        this.toastService.AddWarningToast('A palavra-passe não corresponde à atual.');
      } else {
        this.toastService.AddErrorToast('Ocorreu um erro ao guardar.');
      }
    }
    setTimeout(() => {
      this.Saving = false;
    }, 250);
  }

  SetDefaultAddress(address: UserAddress) {
    this.addresses.defaultaddressid = address.id;
    this.toastService.AddSuccessToast((address.streetname || 'Sem titulo') + ' definida como morada principal');
  }

  DeleteAddress(address: UserAddress) {
    address.active = 0;
  }

  EditAddressLocation(address: UserAddress) {
    this.SelectedAddress = address;
    this.OpenAddressPopup = true;
  }

  addNewAddress() {
    this.router.navigate(['user', 'addresses', 'add'])
  }

  openDefaultPrompt(addressId: number) {
    this.modalService.openDialog({
      data: {
        description: 'Quer definir esta morada como principal?',
        cancel: 'Cancelar',
        confirmation: 'Confirmar'
      }, component: ConfirmationModalComponent
    }).subscribe(result => {
      if (result.action) {
        this.addresses.defaultaddressid = addressId;
        this.SaveChanges();
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-accept',
  templateUrl: './cookie-accept.component.html',
  styleUrls: ['./cookie-accept.component.scss']
})
export class CookieAcceptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  AcceptCookies() {
    localStorage.setItem('PD-COOKIES', '1');
    window.location.reload();
  }

}

<div #dropdownWrapper class="dropdown-wrapper" tabindex="0" [class.disabled]="Disabled"
(click)="!Disabled ? HideDropdown(false) : null">
    <span *ngIf="Field === undefined || Field === null">{{ Label }} {{  Mandatory ? '*' : '' }}</span>
    <span class="regular-dropdown-value" *ngIf="Field !== undefined && Field !== null">{{ DropdownLabel }}</span>
    <div *ngIf="!DropdownState" [@PopDropdown] class="regular-dropdown-items">
        <div *ngIf="Clearable" class="regular-dropdown-item placeholder" (click)="ClearField()"> Limpar </div>
        <div *ngFor="let item of Items" class="regular-dropdown-item" [class.selected]="item.key === Field"
            (click)="item.key !== Field ? SetValue(item.key) : null">
            <div class="regular-dropdown-item-name">
                {{ item.value }}
            </div>
        </div>
        <div *ngIf="!Items || Items.length === 0" class="regular-dropdown-item disabled">
            Sem itens
        </div>
    </div>
    <div class="regular-dropdown-chevron arrow"></div>
</div>
<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="login-wrapper">
    <div *ngIf="Number" class="login-form">
        <h2 class="title">Verificar contacto</h2>
        <div class="login-fields">
            <div class="login-field">
                <label for="">Telemóvel</label>
                <input id="user-password" type="text" [(ngModel)]="NewNumber" (keypress)="$event.keyCode === 13 ? Verify() : null">
            </div>
            <div class="login-field">
                <p class="small-link" (click)="Resend()">Reenviar código de confirmação</p>
            </div>
            <div class="login-field">
                <label for="">Código</label>
                <input id="user-password" type="text" [(ngModel)]="VerificationCode" (keypress)="$event.keyCode === 13 ? Verify() : null">
            </div>
            <span class="field-note">Insira o código que foi enviado no seu registo.</span>
            <div class="login-field">
                <app-generic-button
                [IsLoading]="Verifying"
                [Disabled]="!VerificationCode || VerificationCode.length < 4"
                (ClickedChange)="Verify()" 
                [Label]="'Verificar'"></app-generic-button>
            </div>
        </div>
    </div>
</div>
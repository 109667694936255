import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-dropdown-modal',
  templateUrl: './dropdown-modal.component.html',
  styleUrls: ['./dropdown-modal.component.scss']
})
export class DropdownModalComponent implements OnInit, AfterViewInit {

  @Input() options: DropdownItem[];
  @Input() selection: string;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const classes = document.getElementsByClassName('selected-option');
    if(classes.length) {
      setTimeout(() => {
        classes[0].scrollIntoView();
      }, 250);
    }
  }
  
  close() {
    this.modalService.closeDialog({action: false});
  }

  closeDialog() {
    this.modalService.closeDialog({action: true, data: this.selection});
  }

}

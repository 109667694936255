<div class="supplier-menus-wrapper">
    <ng-container *ngIf="!Loading">
        <ng-container *ngFor="let menuType of MenuByTypes | keyvalue">
            <app-supplier-menu-group [Title]="menuType.key" [Expanded]="false">
                <div *ngFor="let menu of menuType.value" class="menu-wrapper">
                    <app-menu-item [MenuItem]="menu" [Quantity]="1" [ViewOnly]="!Supplier.availability"></app-menu-item>
                </div>
                <div *ngIf="!Menus || Menus.length === 0">
                    Sem menus disponiveis
                </div>
            </app-supplier-menu-group>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="Loading">
        <app-loader></app-loader>
    </ng-container>
</div>
export class ChatMessage {
    id: number;
    origintype: MessageSenderType;
    destinationtype: MessageSenderType;
    ownerid: number;
    receiverid: number;
    message: string;
    seen?: number;
    created_at?: string;
}

export enum MessageSenderType {
    Support = 0,
    Client,
    Supplier,
    Staff
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-spinner',
  templateUrl: './small-spinner.component.html',
  styleUrls: ['./small-spinner.component.scss']
})
export class SmallSpinnerComponent implements OnInit {

  @Input() Loading: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { CategoryType } from 'src/assets/models/management/menu.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  animations: [
    trigger(
      'TriggerMenu', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'translateY(100%)', opacity: 1 }),
            animate('0.3s ease-out', 
                    style({ transform: 'translateY(0)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ transform: 'translateY(0)', opacity: 1 }),
            animate('0.3s ease-out', 
                    style({ transform: 'translateY(100%)', opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class NavigationMenuComponent implements OnInit {

  public ShowMenu: boolean;
  public loggedIn: boolean;
  public CategoryTypes: CategoryType[];

  constructor(private navigationService: NavigationService,
    private sessionService: SessionService,
    private locationService: LocationService) { }

  ngOnInit(): void {
    this.buildItems();
    this.navigationService.ShowNavMenuChange.subscribe(value => {
      this.ShowMenu = value;
    })

    this.locationService.CategoryTypesChange.subscribe(value => {
      this.buildItems();
    });

    this.loggedIn = this.sessionService.LoggedIn;
    this.sessionService.LoggedInChange.subscribe(value => {
      this.loggedIn = value;
    })
  }

  closeMenu() {
    this.navigationService.CloseMenu();
  }

  buildItems() {
    this.CategoryTypes = this.locationService.CategoryTypes;
  }

  travelTo(travel: string[]) {
    const goTo = new NavItem();
    goTo.itemGo = travel;
    this.navigationService.NavigateTo(goTo);
    this.closeMenu();
  }

  ngOnDestroy() {

  }

}

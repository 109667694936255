import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() description: string;
  @Input() cancel: string;
  @Input() confirmation: string;

  constructor(private dialogService: ModalService) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogService.closeDialog();
  }

  acceptDialog() {
    this.dialogService.closeDialog({ action: true });
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  GoTo(to) {
    const goTo = new NavItem();
    goTo.itemGo = [to];
    this.navigationService.NavigateTo(goTo);
  }

}

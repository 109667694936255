<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="login-wrapper">
    <div *ngIf="!InsertNewCredentials" class="login-form">
        <h2>Recuperar palavra-passe</h2>
        <label *ngIf="!SentCodeSuccess" class="info-label">Caso a sua conta exista, será enviado um e-mail com uma ligação onde poderá alterar a sua palavra-passe.</label>
        <div *ngIf="!SentCodeSuccess" class="login-fields">
            <div class="login-field">
                <label for="">E-mail</label>
                <input type="text" [(ngModel)]="UserEmail">
            </div>
            <div class="login-field">
                <app-generic-button
                [Disabled]="!validateEmail(UserEmail)"
                [IsLoading]="LoadingData"
                (ClickedChange)="SendEmail()" 
                [Label]="'Enviar e-mail'"></app-generic-button>
            </div>
            <div class="login-field">
                <app-generic-button
                [CustomClass]="'cancel'"
                (ClickedChange)="GoBack()"
                [Label]="'Cancelar'">
            </app-generic-button>
            </div>
        </div>
        <div *ngIf="SentCodeSuccess">
            <p>
            Verifique a sua caixa de correio e aceda através do link fornecido.
            </p>
            <app-generic-button
                [IsLoading]="LoadingData"
                (ClickedChange)="Retry()" 
                [Label]="'Voltar'">
            </app-generic-button>
        </div>
    </div>
     <!-- Confirm new passwords -->
    <div *ngIf="InsertNewCredentials" class="login-form">
        <h2>Alterar palavra-passe</h2>
        <div class="login-fields">
            <div class="login-field">
                <label for="">Nova palavra-passe</label>
                <input type="password" [(ngModel)]="NewPassword">
            </div>
            <div class="login-field">
                <label for="">Confirme a palavra-passe</label>
                <input type="password" [(ngModel)]="NewPasswordConfirmation">
            </div>
            <span *ngIf="NewPassword && !ValidatePassword(NewPassword)" class="field-error">A palavra-passe deve conter pelo menos 8 caracteres, dos quais 1 simbolo e 1 número</span>
            <span *ngIf="NewPassword && NewPasswordConfirmation && NewPassword !== NewPasswordConfirmation" class="field-error">As palavras-passe não correspondem</span>
            <div class="login-field">
                <app-generic-button
                [Disabled]="!ValidatePassword(NewPassword) || NewPassword !== NewPasswordConfirmation"
                [IsLoading]="LoadingData"
                (ClickedChange)="SendNewPassword()" 
                [Label]="'Alterar palavra-passe'"></app-generic-button>
            </div>
            <div class="login-field">
                <app-generic-button
                [CustomClass]="'cancel'"
                (ClickedChange)="GoBack()"
                [Label]="'Cancelar'">
            </app-generic-button>
            </div>
        </div>
    </div>
</div>
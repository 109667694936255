<div class="limit-form-wrapper">
    <h2 class="user-title">Adicionar morada</h2>
    <div class="steps-wrapper">
        <app-stepper-wrapper [steps]="steps" [(selectedStep)]="selectedStep"></app-stepper-wrapper>
    </div>
    <ng-container [ngSwitch]="selectedStep">
        <ng-container *ngSwitchCase="0">
            <div class="form-wrapper">
                <div class="field-wrapper">
                    <label>Rua</label>
                    <input type="text" placeholder="Insira o nome da sua rua" [(ngModel)]="newAddress.streetname">
                </div>
            </div>
            <div class="form-wrapper">
                <div class="field-wrapper">
                    <label>Cidade</label>
                    <app-dropdown [Label]="'Selecione uma cidade'" [Items]="cityOptions"
                        (FieldChange)="setSelectedCity($event)" [(Field)]="newAddress.cityid">
                    </app-dropdown>
                </div>
                <div class="field-wrapper">
                    <label>Código postal</label>
                    <input type="text" placeholder="xxxx-xxx" [(ngModel)]="newAddress.postcode">
                    <span class="info-label">
                        O seu código postal deve ser no formato 0000-000 (Ex. 4815-005)
                    </span>
                </div>
            </div>
            <div class="steps-options">
                <app-generic-button [Label]="'Cancelar'" (ClickedChange)="goToAddressList()"
                    [CustomClass]="'cancel'"></app-generic-button>
                <app-generic-button [Label]="'Seguinte'"
                    [Disabled]="!newAddress.cityid || !newAddress.streetname || !ValidateZip(newAddress.postcode)"
                    (ClickedChange)="selectedStep = 1; getCoordinatesApprox()"></app-generic-button>
            </div>
        </ng-container>
        <!-- Map selector -->
        <ng-container *ngSwitchCase="1">
            <div *ngIf="detectedLatitude && detectedLongitude" class="location-select-wrapper">
                <span class="info-label">Localize a sua habitação no mapa e pressione no local exato. Um ponto
                    aproximado foi detetado através da morada que inseriu no passo anterior.</span>
                <agm-map class="map-picker" [zoom]="13" [mapTypeId]="'hybrid'" [latitude]="selectedCity.lat"
                    [longitude]="selectedCity.long">
                    <ng-container *ngIf="pickedLatitude && pickedLongitude">
                        <agm-marker [latitude]="pickedLatitude" [longitude]="pickedLongitude">
                        </agm-marker>
                    </ng-container>
                    <ng-container *ngIf="detectedLatitude && detectedLongitude">
                        <agm-marker [latitude]="detectedLatitude" [longitude]="detectedLongitude"
                            [iconUrl]="'../../../assets/media/img/acc.png'">
                        </agm-marker>
                    </ng-container>
                    <ng-container *ngIf="selectedCity">
                        <agm-circle [latitude]="selectedCity.lat" [strokeColor]="'#fff'" [fillOpacity]="0.1"
                            [strokeWeight]="2" [longitude]="selectedCity.long" (circleClick)="SetCoordinates($event)"
                            [radius]="selectedCity.maxdeliveryrange">
                        </agm-circle>
                    </ng-container>
                </agm-map>
            </div>
            <div class="steps-options">
                <app-generic-button [Label]="'Anterior'" (ClickedChange)="selectedStep = 0"
                    [CustomClass]="'cancel'"></app-generic-button>
                <app-generic-button [Label]="'Seguinte'" [Disabled]="!pickedLatitude || !pickedLongitude"
                    (ClickedChange)="selectedStep = 2"></app-generic-button>
            </div>
        </ng-container>
        <!-- Map selector -->
        <ng-container *ngSwitchCase="2">
            <div class="info-confirmation-wrapper">
                <label><b>Morada</b></label>
                <div>
                    {{ newAddress.streetname }}, {{ newAddress.postcode }} {{ selectedCity.city }}
                </div>
                <br>
                <label><b>Ponto selecionado</b></label>
                <div class="info-label">
                    <a target="_blank"
                        [href]="'http://maps.google.com/maps?q='+ pickedLatitude + ',' + pickedLongitude">{{
                        pickedLatitude + '. ' + pickedLongitude }}</a>
                </div>
                <div class="info-label">
                    Confirme que o ponto que selecionou corresponde à sua morada.
                    <br>
                    Caso contrário, pode voltar aos passos anteriores e alterar o que for necessário.
                </div>
            </div>
            <div class="steps-options">
                <app-generic-button [Label]="'Anterior'" (ClickedChange)="selectedStep = 1"
                    [CustomClass]="'cancel'"></app-generic-button>
                <app-generic-button [Label]="'Adicionar morada'" [IsLoading]="Saving"
                    (ClickedChange)="saveChanges()"></app-generic-button>
            </div>
        </ng-container>
    </ng-container>
</div>
<div class="stepper-wrapper">
    <div class="steps-list">
        <div *ngFor="let step of steps; let index = index;" [class.selected]="index <= selectedStep" class="step">
            <div *ngIf="index > 0" class="step-line"></div>
            <div class="step-info">
                <span class="step-number">{{ index + 1 }}</span>
                <span class="step-name">{{ step.name }}</span>
            </div>
            <div *ngIf="(steps.length - 1) !== index" class="step-line"></div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from 'src/assets/models/ui/nav-item.model';
import { CartService } from 'src/app/services/cart.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {

  @Input() ShowCart = true;
  public TotalCartItems = 0;
  public LoggedIn: boolean;

  constructor(private shoppingCart: CartService, private navigationService: NavigationService,
    private sessionService: SessionService) { }

  ngOnInit(): void {
    this.TotalCartItems = this.shoppingCart.TotalItems;
    this.shoppingCart.TotalItemsChange.subscribe(value => {
      this.TotalCartItems = value;
    })

    this.LoggedIn = this.sessionService.LoggedIn;
    this.sessionService.LoggedInChange.subscribe(value => {
      this.LoggedIn = value;
    });
  }

  OpenCart() {
    this.shoppingCart.OpenShoppingCart();
  }

  Login() {
    const goToLogin = new NavItem();
    goToLogin.itemGo = ['login']
    this.navigationService.NavigateTo(goToLogin);
  }

  Logout() {
    this.sessionService.Logout();
  }

  UserProfile() {
    const goToLogin = new NavItem();
    goToLogin.itemGo = ['profile']
    this.navigationService.NavigateTo(goToLogin);
    // this.sessionService.Logout();
  }

  GoHome() {
    const goToLogin = new NavItem();
    goToLogin.itemGo = ['home']
    this.navigationService.NavigateTo(goToLogin);
  }

  OpenMenu() {
    this.navigationService.OpenMenu();
  }

}

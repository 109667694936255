<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <ng-container *ngIf="!Loading">
        <div class="back-option-wrapper" (click)="goBack()">
            <span class="arrow left"></span> <label>{{ 'Voltar' }}</label>
        </div>
        <h2 class="order-ref">A sua encomenda {{ OrderRef || '' }}</h2>
        <div class="order-track-wrapper">
            <div class="order-state-wrapper" [class.filled]="OrderStatus >= 1">
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/order.svg'"></span>
                    </div>
                    <div class="state-name">
                        Pendente
                    </div>
                </div>

            </div>
            <div class="order-state-wrapper" [class.filled]="OrderStatus >= 2">
                <div class="state-path">
                    <div class="state-column"></div>
                </div>
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg"
                            [inlineSVG]="'../../../assets/media/img/' + (OrderStatus === 6 ? 'sad.svg' : 'checkmark.svg')"></span>
                    </div>
                    <div class="state-name">
                        {{ OrderStatus === 6 ? 'Cancelada' : 'Aceite' }}
                    </div>
                </div>
                <div *ngIf="OrderStatus === 6" class="order-notes">
                    {{ Notes || 'Não foi apresentado um motivo para o cancelamento.' }}
                </div>
            </div>
            <div class="order-state-wrapper"
                [class.filled]="OrderStatus >= 2 && OrderStatus !== 6 && OrderStatus !== 7">
                <div class="state-path">
                    <div class="state-column"></div>
                </div>
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/cooking.svg'"></span>
                    </div>
                    <div class="state-name">
                        A preparar
                    </div>
                </div>
                <div *ngIf="OrderStatus === 2" class="order-notes">
                    há {{ LastUpdate }}
                </div>
            </div>
            <div class="order-state-wrapper"
                [class.filled]="OrderStatus >= 3 && OrderStatus !== 6 && OrderStatus !== 7">
                <div class="state-path">
                    <div class="state-column"></div>
                </div>
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/box.svg'"></span>
                    </div>
                    <div class="state-name">
                        Pronta
                    </div>
                </div>
                <div *ngIf="OrderStatus === 3" class="order-notes">
                    há {{ LastUpdate }}
                </div>
            </div>
            <div class="order-state-wrapper"
                [class.filled]="OrderStatus >= 4 && OrderStatus !== 6 && OrderStatus !== 7">
                <div class="state-path">
                    <div class="state-column"></div>
                </div>
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/bike-delivery.svg'"></span>
                    </div>
                    <div class="state-name">
                        A caminho
                    </div>
                </div>
                <div *ngIf="OrderStatus === 4" class="order-notes">
                    há {{ LastUpdate }}
                </div>
            </div>
            <div class="order-state-wrapper"
                [class.filled]="OrderStatus >= 5 && OrderStatus !== 6 && OrderStatus !== 7">
                <div class="state-path">
                    <div class="state-column"></div>
                </div>
                <div class="order-state-info">
                    <div class="state-icon">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/home-location.svg'"></span>
                    </div>
                    <div class="state-name">
                        Entregue
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="Loading">

    </ng-container>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { City } from 'src/assets/models/management/city.model';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})
export class LocationModalComponent implements OnInit {

  @Input() options: City[];
  @Input() selectedCity: City;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.closeDialog({action: false});
  }

  closeDialog() {
    this.modalService.closeDialog({action: true, data: this.selectedCity});
  }

}

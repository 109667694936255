import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  GoTo(where: string) {
    const goTo = new NavItem();
    goTo.itemGo = [where];
    this.navigationService.NavigateTo(goTo);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrls: ['./order-details-modal.component.scss']
})
export class OrderDetailsModalComponent implements OnInit {

  @Input() orderDetails: any[];
  public orderedDetails: {};

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.buildOrderedInfo();
  }

  buildOrderedInfo() {
    const orderedDetails = {};
    this.orderDetails.forEach(element => {
      if (!orderedDetails[element.suppliername]) {
        orderedDetails[element.suppliername] = [];
      }
      orderedDetails[element.suppliername].push(element);
    });
    this.orderedDetails = orderedDetails;
  }


  closeDialog() {
    this.modalService.closeDialog();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-supplier-menu-group',
  templateUrl: './supplier-menu-group.component.html',
  styleUrls: ['./supplier-menu-group.component.scss']
})
export class SupplierMenuGroupComponent implements OnInit {

  @Input() Title: string;
  @Input() Expanded: boolean;
  @Input() Minimizable = true;
  @Input() Deletable = true;
  @Input() AccordionIndex?: number;
  @Input() AccordionDeleteCommand?: string;
  @Output() OptionsEventSender: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  ExpandAccordion() {
    this.Expanded = !this.Expanded;
  }

}

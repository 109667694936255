<div class="location-modal-wrapper">
    <div class="location-wrapper  custom-scrollbar">
        <span *ngFor="let orderSupplier of orderedDetails | keyvalue">
            <div class="supplier-name">{{ orderSupplier.key }}</div>
            <div class="order-lines-wrapper">
                <div *ngFor="let orderLine of orderSupplier.value">
                    {{ orderLine.menuname + (orderLine.menusizename ? ' (' + orderLine.menusizename
                    + ') - ' : ' - ')
                    + '(x' + orderLine.quantity
                    + ') ' }}
                </div>
            </div>
        </span>
    </div>
    <div class="location-options-wrapper">
        <app-generic-button [Label]="'Cancelar'" (ClickedChange)="closeDialog()" [CustomClass]="'cancel'">
        </app-generic-button>
    </div>
</div>
<div class="chat-wrapper">
    <div class="conversation-wrapper">
        <div *ngFor="let message of Messages"
        [@PopMessage] 
        class="conversation-message"
        [class.by-user]="message.ownerid === 1"
        [class.by-other]="message.ownerid !== 1">
        {{ message.message }}
        </div>
        <ng-container *ngIf="!Messages || Messages.length === 0">
            <div class="empty-message">
            Envie uma mensagem para inicar a conversa
            </div>
        </ng-container>
    </div>
    <div class="conversation-answer">
        <textarea 
        placeholder="Responder..."
        [(ngModel)]="Message"
        cols="30" rows="1"></textarea>
        <button class="answer-button" (click)="SendMessage()">
        <span class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/paper-plane.svg'"></span>
        </button>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { CartItem } from 'src/assets/models/ui/cart-item.model';
import { OrderFollow } from 'src/assets/models/management/order.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient,private sessionService: SessionService) { }

  async checkOutOrder(cartItems: CartItem[], address: string, notes: string) {
    return await this.http.post<any>(`${environment.baseUrl}create-order`, { order: cartItems, 
      notes: notes, 
      addressid: 2,
      paymentid: 1} , { headers: this.sessionService.BuildHeaders() }).toPromise();
  }

  async getCartFullCost(cartSystem: any) {
    return await this.http.post<any>(`${environment.baseUrl}order-price`, cartSystem, { headers: this.sessionService.BuildHeaders(), observe: 'response' }).toPromise();
  }

  async createOrder(cartSystem: any) {
    return await this.http.post<number>(`${environment.baseUrl}create-order`, cartSystem, { headers: this.sessionService.BuildHeaders() }).toPromise();
  }

  async getOrderByReference(orderRef: number) {
    return await this.http.get<OrderFollow>(`${environment.baseUrl}get-order/${orderRef}`, { headers: this.sessionService.BuildHeaders() }).toPromise();
  }

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category, CategoryType, Menu } from 'src/assets/models/management/menu.model';
import { Supplier, SupplierParameters } from 'src/assets/models/management/supplier.model';

import { City } from 'src/assets/models/management/city.model';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SuppliersService {

  public Parameters: SupplierParameters = new SupplierParameters();
  public ParametersChanged: Subject<SupplierParameters> = new Subject<SupplierParameters>();

  constructor(private http: HttpClient) {
    this.ParametersChanged.subscribe(params => {
      this.Parameters = params;
    });
  }

  ChangeParameters(params: SupplierParameters) {
    this.ParametersChanged.next(params);
  }

  SetDeliveryHours(hours: string) {
    if(hours) {
      this.Parameters.start = hours;
    } else {
      this.Parameters.start = null;
    }
    this.ParametersChanged.next(this.Parameters);
  }

  SetCity(city: number) {
    this.Parameters.city = city;
    this.ParametersChanged.next(this.Parameters);
  }

  SetCategoryType(category: string) {
    sessionStorage.setItem('PD-CAT', category);
    this.Parameters.category_type = category;
    this.ParametersChanged.next(this.Parameters);
  }

  SetCategory(category: number) {
    this.Parameters.category = category;
    this.ParametersChanged.next(this.Parameters);
  }

  async getSuppliers(supplierParams: SupplierParameters) {

    return await this.http.post<Supplier[]>(`${environment.baseUrl}supplier`, supplierParams).toPromise();
  }

  async getSupplierMenu(supplierId, supplierParams: SupplierParameters) {
    return await this.http.post<Menu[]>(`${environment.baseUrl}supplier-menu/${supplierId}`, supplierParams).toPromise();
  }

  async getMenuInfo(menuId: number) {
    return await this.http.get<Menu>(`${environment.baseUrl}menu/${menuId}`).toPromise();
  }

  async getCities() {

    return await this.http.get<City[]>(`${environment.baseUrl}cities`, {}).toPromise();
  }

  async getCategoryTypes() {

    return await this.http.get<CategoryType[]>(`${environment.baseUrl}category-type`).toPromise();
  }

  async getCategories(categoryType: string) {

    return await this.http.get<Category[]>(`${environment.baseUrl}category/${categoryType}`).toPromise();
  }

}

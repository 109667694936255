export class PortalUser {
    id: number;
    email: string;
    password: string;
}

export class LoggedUserInfo {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
    profiletype: string;
    profileid: number;
}

export class UserRegister {
    firstname: string;
    lastname: string;
    taxnumber: string;
    phonenumber: number;
    email: string;
    old_password?: string;
    password: string;
    confirm_password: string;
    address = new UserAddress();
    addresses: UserAddress[];
    defaultaddressid: number;
    sendcode: boolean;
}

export class UserAddress {
    id: number;
    streetname: string;
    postcode: string;
    lat: any;
    long: any;
    cityid: number;
    active: number;
    country = 'Portugal';
}

export class UserAddressList {
    defaultaddressid: number;
    addresses: UserAddress[];
}

export class UserContact {
    sendername: string;
    senderphone: string;
    senderemail: string;
    sendermessage: string;
    sendercity: string;
}
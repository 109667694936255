<div class="dsp-accordion-wrapper" [class.expanded]="Expanded || !Minimizable">
    <div class="accordion-header">
        <div class="accordion-title">{{ Title }}
            <ng-content select="[slot=title]"></ng-content>
        </div>
        <div class="accordion-options-wrapper">
            <div *ngIf="Minimizable" class="accordion-option expand-option"
            (click)="ExpandAccordion()">
                <div class="accordion-option-icon arrow left"></div>
            </div>
            <div *ngIf="Deletable" class="accordion-option" (click)="DeleteEntry()">
                <div class="accordion-option-icon" [inlineSVG]="'../../../assets/media/img/icons/close.svg'"></div>
            </div>
        </div>
    </div>
    <div class="accordion-body" [class.closed]="!Expanded">
        <ng-content></ng-content>
    </div>
</div>
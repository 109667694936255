<div class="topnav-container">
    <app-topnav [ShowCart]="false"></app-topnav>
</div>
<div class="checkout-container">
    <div class="limit-form-wrapper">
        <ng-container *ngIf="!PageLoader">
            <div class="cart-items-full-wrapper">
                <div class="cart-items-wrapper">
                    <ng-container *ngFor="let supplierItems of ItemsBySupplier | keyvalue">
                        <app-supplier-menu-group [Title]="supplierItems.key" [Expanded]="true">
                            <div *ngFor="let item of supplierItems.value" class="cart-item-wrapper">
                                <app-menu-item [MenuItem]="item.menu"
                                [(Quantity)]="item.qty"
                                [Id]="item.id"
                                [DeleteMode]="true"
                                [WriteNotes]="true"
                                [(Notes)]="item.notes"
                                (SizeChange)="SaveChanges()"
                                (QuantityChange)="SaveChanges()"></app-menu-item>
                            </div>
                        </app-supplier-menu-group>
                    </ng-container>
                    <div *ngIf="!Items || Items.length === 0" class="shopping-cart-empty">
                        Não tem produtos no seu carrinho.
                    </div>
                </div>
                <div class="cart-total-wrapper">
                    <div class="cart-total-item-wrapper">
                        <div class="cart-total-item">Custo de pedido</div>
                        <div class="cart-total-item text-right">
                            {{ OrderPrice }} €
                        </div>
                    </div>
                    <div class="cart-total-item-wrapper">
                        <div class="cart-total-item">Custo de entrega</div>
                        <div class="cart-total-item text-right">
                            {{ AddressCost }} €
                        </div>
                    </div>
                    <div class="cart-total-item-wrapper">
                        <div class="cart-total-item">Embalagens</div>
                        <div class="cart-total-item text-right">
                            {{ PackageCost }} €
                        </div>
                    </div>
                    <div *ngIf="LateTax" class="cart-total-item-wrapper">
                        <div class="cart-total-item red-field">Taxa noturna</div>
                        <div class="cart-total-item text-right red-field">
                            {{ LateTax }} €
                        </div>
                    </div>
                    <div class="cart-total-item-wrapper bordered">
                        <div class="cart-total-item bold">Total</div>
                        <div class="cart-total-item text-right">{{ TotalCartPrice }} €</div>
                    </div>
                </div>
                <ng-container *ngIf="Loading">
                    <div class="cart-items-processing">
                        <app-loader></app-loader>
                    </div>
                </ng-container>
            </div>
        
            <div class="section-container">
                <label>Morada de entrega</label>
                <app-dropdown [Items]="UserAddressChoices" [(Field)]="SelectedAddress"
                (FieldChange)="ApplyNewAddress()"></app-dropdown>
            </div>
            <div class="section-container">
                <label>Hora de entrega</label>
                <input type="text" [disabled]="true" value="{{ DeliveryHour }}">
            </div>
            <div *ngIf="IsBusy" class="section-container delay-wrapper">
                <i class="regular-svg" [inlineSVG]="'/assets/media/img/warning.svg'"></i>
                <label class="delay-label">Devido a um elevado número de encomendas, poderá haver algum atraso</label>
            </div>
            <div class="section-container">
                <label>Notas</label>
                <textarea cols="30" rows="5" [(ngModel)]="OrderNotes"></textarea>
            </div>
            <div class="section-container">
                <div class="tax-wrapper">
                    <input type="checkbox" [checked]="useTax"
                    (change)="useTax = !useTax"> Emitir fatura
                </div>
                <ng-container *ngIf="useTax">
                    <label>NIF</label>
                    <input type="text" value="{{ UserTaxNumber }}">
                </ng-container>
            </div>
            <div class="section-container">
                <label>Método de pagamento</label>
                <div class="payment-methods">
                    <div class="payment-method" [class.selected]="PaymentType === 1" (click)="SelectPaymentType(1)">
                        <span class="regular-svg" [inlineSVG]="'../../../assets/media/img/money.svg'"></span>
                        <span>Numerário</span>
                    </div>
                    <div class="payment-method" [class.selected]="PaymentType === 2" (click)="SelectPaymentType(2)">
                        <span class="regular-big-svg" [inlineSVG]="'../../../assets/media/img/mbway.svg'"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="PaymentType === 1" class="section-container">
                <label>Quantia com o qual vai pagar</label>
                <input type="text" [(ngModel)]="PaymentQuantity">
                <div *ngIf="PaymentQuantity && !ValidatePaymentQuantity(PaymentQuantity)" class="field-error">A quantia é inferior ao valor a pagar</div>
            </div>
            <div class="section-container">
                <app-generic-button [Label]="'Encomendar'"
                [Disabled]="PaymentType === 1 && !ValidatePaymentQuantity(PaymentQuantity)" (ClickedChange)="confirmOrderModal()">
        
                </app-generic-button>
            </div>
        </ng-container>
        <ng-container *ngIf="PageLoader">
            <app-loader></app-loader>
        </ng-container>
    </div>
</div>
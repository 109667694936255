import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { ChatMessage, MessageSenderType } from 'src/assets/models/management/message.model';

@Component({
  selector: 'app-chat-support',
  templateUrl: './chat-support.component.html',
  styleUrls: ['./chat-support.component.scss'],
  animations: [
    trigger(
      'PopMessage', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'scale(0)' }),
            animate('0.15s ease-out', 
                    style({ transform: 'scale(1.1)' })),
            animate('0.15s ease-out', 
            style({ transform: 'scale(1)' }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ transform: 'scale(1)' }),
            animate('0.3s ease-in', 
                    style({ transform: 'scale(0)' }))
          ]
        )
      ]
    )
  ]
})
export class ChatSupportComponent implements OnInit {

  public Message: string;
  public Messages: ChatMessage[];

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.Messages = [];
  }

  SendMessage() {
    this.Messages.push({
      id: 1,
      origintype: MessageSenderType.Client,
      destinationtype: MessageSenderType.Support,
      message: this.Message,
      ownerid: 1,
      receiverid: 2
    });
    this.Message = '';
  }

}

<div *ngIf="!Categories || Categories.length !== 0" class="category-filters-wrapper">
    <div class="category-filter" (click)="ClearCategory()">
        <div class="category-image-wrapper">
            <img [src]="'../../../assets/media/defaults/card.jpg'" alt="">
        </div>
        <div class="category-name" [class.selected]="!SelectedCategory">
            <label> Todos </label>
        </div>
    </div>
    <div *ngFor="let category of Categories" class="category-filter" (click)="SelectCategory(category.id)">
        <div class="category-image-wrapper">
            <img [src]="category.image ? category.image : '../../../assets/media/defaults/card.jpg'" (error)="SetDefault($event, category.image)">
        </div>
        <div class="category-name" [class.selected]="SelectedCategory === category.id">
            <label> {{ category.name }} </label>
        </div>
    </div>
</div>
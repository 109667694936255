import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabItem } from 'src/assets/models/ui/tab-item.model';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {

  @Input() Items: TabItem[];
  @Input() SelectedTab: TabItem;
  @Output() SelectedTabChange: EventEmitter<TabItem> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  SetSelectedTab(tab: TabItem) {
    this.SelectedTab = tab;
    this.SelectedTabChange.emit(tab);
  }

}

<div class="limit-form-wrapper">
    <h2 class="user-title">Dados pessoais</h2>
    <ng-container *ngIf="UserProfile">
        <div class="form-wrapper">
            <div class="field-wrapper">
                <label>Nome</label>
                <input type="text" [(ngModel)]="UserProfile.firstname">
            </div>
            <div class="field-wrapper">
                <label>Apelido</label>
                <input type="text" [(ngModel)]="UserProfile.lastname">
            </div>
        </div>
        <div class="form-wrapper">
            <div class="field-wrapper">
                <label>NIF (opcional)</label>
                <input type="text" [(ngModel)]="UserProfile.taxnumber">
            </div>
        </div>
        <h2 class="user-title">Contactos</h2>
        <div class="form-wrapper">
            <div class="field-wrapper">
                <label>E-mail</label>
                <input type="text" disabled [(ngModel)]="UserProfile.email">
            </div>
        </div>
        <div class="form-wrapper">
            <div class="field-wrapper">
                <label>Telemóvel</label>
                <input type="text" [(ngModel)]="UserProfile.phonenumber" disabled>
            </div>
        </div>
        <ng-container *ngIf="ChangePassword">
            <h2 class="user-title">Alterar palavra-passe</h2>
            <div class="form-wrapper">
                <div class="field-wrapper">
                    <label>Palavra-passe</label>
                    <input type="password" [(ngModel)]="UserProfile.old_password">
                </div>
            </div>
            <div class="form-wrapper">
                <div class="field-wrapper">
                    <label>Nova palavra-passe</label>
                    <input type="password" [(ngModel)]="UserProfile.password">
                </div>
                <div class="field-wrapper">
                    <label>Repita a nova palavra-passe</label>
                    <input type="password" [(ngModel)]="UserProfile.confirm_password">
                </div>
            </div>
        </ng-container>
        <div class="button-wrapper">
            <app-generic-button [Label]="!ChangePassword ? 'Alterar palavra-passe' : 'Cancelar alteração'"
                [CustomClass]="'cancel'" (ClickedChange)="TriggerChangePassword()"></app-generic-button>
        </div>
        <div class="button-wrapper">
            <app-generic-button [Label]="'Guardar alterações'" [IsLoading]="Saving" (ClickedChange)="SaveChanges()">
            </app-generic-button>
        </div>
    </ng-container>
</div>
<div class="schedule-modal-wrapper">
    <div class="schedule-selector-wrapper">
        <div class="schedule-selector" [class.selected]="showToday" (click)="showToday = true">
            Hoje
        </div>
        <div class="schedule-selector" [class.selected]="!showToday"  (click)="showToday = false">
            Amanhã
        </div>
    </div>
    <div id="time-spans" class="schedule-wrapper  custom-scrollbar">
        <div *ngIf="showToday" class="timespan-wrapper">
            <span *ngFor="let time of AvailableHours" [class.selected-time]="DeliveryTime === time.key" (click)="DeliveryTime = time.key">
                {{ time.value }}
            </span>
            <div *ngIf="!AvailableHours || AvailableHours.length == 0">
                Sem horários disponívels para hoje
            </div>
        </div>
        <div *ngIf="!showToday" class="timespan-wrapper">
            <span *ngFor="let time of AvailableHoursTomorrow" [class.selected-time]="DeliveryTime === time.key" (click)="DeliveryTime = time.key">
                {{ time.value }}
            </span>
        </div>
    </div>
    <div class="schedule-options-wrapper">
        <app-generic-button [Label]="'Cancelar'" (ClickedChange)="close()" [CustomClass]="'cancel'">
        </app-generic-button>
        <app-generic-button [Label]="'Continuar'" (ClickedChange)="closeDialog()" [Disabled]="!DeliveryTime">
        </app-generic-button>
    </div>
</div>
import { Component, ComponentFactoryResolver, HostListener, OnInit, Type, ViewChild } from '@angular/core';
import { DialogDirective } from 'src/app/services/dialog.directive';
import { ModalService } from 'src/app/services/modal.service';

export class DialogItem {
  constructor(public component: Type<any>, public data?: any) { }
}

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeDialog();
  }

  public dialog?: DialogItem;
  @ViewChild(DialogDirective, { static: true }) dialogHost!: DialogDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private modalService: ModalService) { }

  ngOnInit(): void {
    this.modalService.activeModal.subscribe(value => {
      if (value) {
        this.dialog = value;
        this.loadComponent();
      } else {
        this.dialog = undefined;
        setTimeout(() => {
          this.dialogHost.viewContainerRef.clear();
        }, 600);
      }
    })
  }

  loadComponent() {
    if (this.dialog) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dialog?.component);
      const viewContainerref = this.dialogHost.viewContainerRef;
      viewContainerref.clear();

      const componentRef = viewContainerref.createComponent(componentFactory);
      /* Pass data values to components */
      if (this.dialog.data) {
        for (const key in this.dialog.data) {
          if (Object.prototype.hasOwnProperty.call(this.dialog.data, key)) {
            componentRef.instance[key] = this.dialog.data[key];
          }
        }
      }
    }
  }

  closeDialog() {
    this.modalService.closeDialog();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Menu } from 'src/assets/models/management/menu.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-supplier-page',
  templateUrl: './supplier-page.component.html',
  styleUrls: ['./supplier-page.component.scss']
})
export class SupplierPageComponent implements OnInit {

  public isLoading: boolean;
  public supplierId: number;
  public selectedCategory: string;
  public supplierName: string;
  public menuByTypes = {};
  public menus: Menu[] = [];

  constructor(private activeRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private supplierService: SuppliersService) { }

  async ngOnInit() {
    this.isLoading = true;
    this.supplierId = this.activeRoute.snapshot.params['id'];
    await this.BuildMenuByTypes();
    this.isLoading = false;
  }

  async BuildMenuByTypes() {
    this.menuByTypes = {};
    this.menus = await this.supplierService.getSupplierMenu(this.supplierId, this.supplierService.Parameters);
    this.supplierName = this.menus[0].suppliername;
    this.menus.forEach(menu => {
      if(!this.menuByTypes[menu.menutypename]) {
        this.menuByTypes[menu.menutypename] = [];
      }

      this.menuByTypes[menu.menutypename].push(menu);
    });
  }

  selectCategory(category: string | null) {
    this.selectedCategory = category;
  }

  goBack() {
    const navItem = new NavItem();
    navItem.itemGo = ['home', 'suppliers'];
    this.navigationService.NavigateTo(navItem);
  }
}

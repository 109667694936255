<div class="user-address-wrapper">
    <div class="user-address-header">
        <div class="user-address-title">
            {{ userAddress.streetname }}
        </div>
    </div>
    <div class="user-address-information">
        <div class="user-address-info">
            {{ userAddress.postcode }}, {{ selectedCity.value }}
        </div>
        <div class="user-address-coordinates">
            GPS: <a href="">{{ userAddress.lat }}, {{ userAddress.long }} </a>
        </div>
        <div *ngIf="!isDefault" class="user-address-action" (click)="triggerDefaultPrompt()">
            Definir como principal
        </div>
        <div *ngIf="isDefault" class="user-address-main">
            Principal
        </div>
    </div>
</div>
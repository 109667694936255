import { CartItem } from "../ui/cart-item.model";

export class Order {
    menuitems: CartItem[];
    addressid: number;
    notes = '';
    cityid: number;
    paymentid: number;
    deliverytime: string;
    paymentvalue: number;
    taxnumber: string;
}

export class OrderFollow {
    cancelnotes: string;
    updated_at: string;
    ordernumber: string;
    orderstatusid: number;
}

export class OrderConfirmationResult {
    addressid: number;
    menuitems: CartItem[];
    deliverytime: string;
    deliveryprice: number;
    totalprice: number;
    orderprice: number;
    nightordertax: number;
    packagetax: number;
    deliveryquantity: number;
    isbusyhour: number;
    taxnumber: string;
}
<div class="dsp-toast-wrapper" 
[ngClass]="{ 
'success': ToastMessage.type === 1,
'error': ToastMessage.type === 2,
'warning': ToastMessage.type === 3 }">
    <div class="dsp-toast-dismiss dsp-general-svg white" (click)="DismissMessage()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="#BFC5CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 4L12 12" stroke="#BFC5CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>            
    </div>
    <div class="dsp-toast-body">
        <div class="dsp-toast-message">
            {{ ToastMessage.message }}
        </div>
    </div>
    <div class="dsp-toast-timer">
        <div class="dsp-toast-timer-bar" [ngStyle]="{'animation-duration': ToastTimerCSS}"></div>
    </div>
</div>
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit, AfterViewInit {

  public isLoading: boolean;
  public AvailableHours: any[];
  public AvailableHoursTomorrow: any[];
  public maxHour = 24;
  public minuteInterval = 15;
  public DeliveryTime: string;
  public showToday = true;

  @ViewChild('selectedTime', {static: false}) supplierWrapper?: ElementRef;


  constructor(private utilsService: UtilsService, private modalService: ModalService) { }


  ngOnInit(): void {
    this.isLoading = true;
    this.CalculateAvailableHours();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    const classes = document.getElementsByClassName('selected-time');
    if(classes.length) {
      setTimeout(() => {
        classes[0].scrollIntoView();
      }, 250);
    }
  }

  CalculateAvailableHours() {
    this.AvailableHours = [];
    this.AvailableHoursTomorrow = [];
    const selectedTime = sessionStorage.getItem('PD-TIME');
    const todaysDay = new Date().getDate();
    const weekDay = new Date().getDay();
    const isWeekend = (weekDay === 0 || weekDay === 6 || weekDay === 5) ? true : false;
    const minTime = isWeekend ? 40 : 20;

    let currentDate = new Date();

    currentDate.setHours(currentDate.getUTCHours() + 1);
    currentDate.setMinutes(currentDate.getUTCMinutes());
    const minDateToAsk = new Date(currentDate);
    minDateToAsk.setMinutes(currentDate.getMinutes() + minTime);

    currentDate.setMinutes(this.minuteInterval);

    const composedDate = new Date(currentDate);
    composedDate.setHours(composedDate.getHours() + 24);
    const possibleDates = [];

    while (currentDate < composedDate) {

      const nextDate = new Date(currentDate);
      nextDate.setMinutes(nextDate.getMinutes() + this.minuteInterval);
      possibleDates.push({ start: new Date(currentDate), end: nextDate });

      currentDate.setMinutes(currentDate.getMinutes() + this.minuteInterval);
    }

    const blackListedHours = ['18:45', '19:15', '19:45', '20:15', '20:45'];
    possibleDates.forEach((interval, index) => {
      if (possibleDates[index + 1]) {
        if (interval.start > minDateToAsk) {
          const todayNumber = interval.start.getDate();
          const hourSliced = ('0' + interval.start.getHours()).slice(-2);
          const minuteSliced = ('0' + interval.start.getMinutes()).slice(-2);
          const timeRole = hourSliced + ':' + minuteSliced;

          const hourSlicedNext = ('0' + interval.end.getHours()).slice(-2);
          const minuteSlicedNext = ('0' + interval.end.getMinutes()).slice(-2);
          const timeRoleNext = hourSlicedNext + ':' + minuteSlicedNext;

          const dateTime = this.utilsService.ConvertDateTime(interval.start, true);
          const intervalAsString = dateTime.date + ' ' + dateTime.time;

          const intervalComposedText = timeRole + ' - ' + timeRoleNext;

          if (isWeekend && blackListedHours.indexOf(dateTime.time) > -1) {

          } else {
            if (todayNumber == todaysDay) {
              this.AvailableHours.push({ key: intervalAsString, value: intervalComposedText });
            } else {
              this.AvailableHoursTomorrow.push({ key: intervalAsString, value: intervalComposedText });
            }
          }

          if (selectedTime === intervalAsString) {
            this.DeliveryTime = intervalAsString;
            this.showToday = todayNumber == todaysDay ? true : false;
          }
        }
      }
    });

    if(this.AvailableHours.length === 0) {
      this.showToday = false;
    }
  }

  close() {
    this.modalService.closeDialog({action: false});
  }

  closeDialog() {
    this.modalService.closeDialog({action: true, data: this.DeliveryTime});
  }

}

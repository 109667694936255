import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ToastMessage } from 'src/assets/models/ui/toast-message.model';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'lst-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ maxHeight: 0, opacity: 0, transform: 'translateY(100%)' }),
            animate('0.3s ease-out', 
                    style({ maxHeight: 300, opacity: 1, transform: 'translateY(0)' }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ maxHeight: 300, opacity: 1, transform: 'translateY(0)' }),
            animate('0.3s ease-in', 
                    style({ maxHeight: 0, opacity: 0, transform: 'translateY(100%)' }))
          ]
        )
      ]
    )
  ]
})
export class ToastComponent implements OnInit {

  public ToastMessages: ToastMessage[];

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.ToastMessages = this.toastService.GetMessages();
  }

  DismissMessage(message: ToastMessage) {
    this.toastService.DismissMessage(message.id);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserAddress } from 'src/assets/models/management/user.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss']
})
export class UserAddressComponent implements OnInit {

  @Input() userAddress: UserAddress;
  @Input() isDefault: boolean;
  @Input() cities: DropdownItem[] = [];
  @Output() setDefaultChange: EventEmitter<number> = new EventEmitter();
  public expanded = false;
  public selectedCity: DropdownItem;
  
  constructor() { }

  ngOnInit(): void {
    this.selectedCity = this.cities.find(city => {
      return city.key === this.userAddress.cityid;
    });
  }

  triggerDefaultPrompt() {
    this.setDefaultChange.emit(this.userAddress.id);
  }

}

<div class="dsp-accordion-wrapper" [class.expanded]="Expanded || !Minimizable">
    <div class="accordion-header" (click)="ExpandAccordion()">
        <div class="accordion-options-wrapper">
            <div *ngIf="Minimizable" class="accordion-option expand-option">
                <div class="accordion-option-icon arrow right"></div>
            </div>
        </div>
        <div class="accordion-title">{{ Title }}</div>
    </div>
    <div class="accordion-body" [class.closed]="!Expanded">
        <ng-content></ng-content>
    </div>
</div>
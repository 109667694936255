import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { CheckoutDoneComponent } from './components/checkout-done/checkout-done.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ColaborateComponent } from './components/colaborate/colaborate.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { FollowOrderComponent } from './components/follow-order/follow-order.component';
import { HelpComponent } from './components/help/help.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MainComponent } from './components/main/main.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { RegisterComponent } from './components/register/register.component';
import { SupplierPageComponent } from './components/supplier-page/supplier-page.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { UserAddressesComponent } from './components/user-addresses/user-addresses.component';
import { UserHistoryComponent } from './components/user-history/user-history.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { UserSupportComponent } from './components/user-support/user-support.component';
import { VerifyPhoneComponent } from './components/verify-phone/verify-phone.component';
import { AuthGuard } from './services/authentication/auth.guard';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'home', component: MainComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'home', component: MainComponent, children: [
    {path: 'suppliers', component: SuppliersComponent},
  ]},
  {path: 'suppliers/:id', component: SupplierPageComponent},
  {path: 'track/:reference', component: FollowOrderComponent, canActivate: [AuthGuard]},
  {path: 'user', component: ProfileComponent, canActivate: [AuthGuard], children: [
    {path: 'account', component: UserInformationComponent},
    {path: 'addresses', component: UserAddressesComponent},
    {path: 'addresses/add', component: AddAddressComponent},
    {path: 'history', component: UserHistoryComponent},
    {path: 'notifications', component: UserNotificationsComponent},
    {path: 'support', component: UserSupportComponent},
  ]},
  {path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard]},
  {path: 'checkout/success/:id', component: CheckoutDoneComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'login/:redirect', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'recover-password', component: RecoverPasswordComponent},
  {path: 'recover-password/:token', component: RecoverPasswordComponent},
  {path: 'colaborate', component: ColaborateComponent},
  {path: 'partner', component: PartnersComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'verify-phone', component: VerifyPhoneComponent},
  {path: 'verify-phone/:redirect', component: VerifyPhoneComponent},
  {path: 'cookie-policy', component: CookiePolicyComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'help', component: HelpComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="login-wrapper">
   <div class="limit-form-wrapper">
    <h2 class="title">Novo registo</h2>
    <div class="steps-wrapper">
        <app-stepper-wrapper [steps]="steps" [(selectedStep)]="CurrentStep"></app-stepper-wrapper>
    </div>
    <ng-container [ngSwitch]="CurrentStep">
        <ng-container *ngSwitchCase="0">
            <div class="login-form">
                <div class="login-fields">
                    <div class="login-field">
                        <label for="">Nome *</label>
                        <input type="text" [(ngModel)]="UserInfo.firstname">
                    </div>
                    <div class="login-field">
                        <label for="">Apelido *</label>
                        <input type="text" [(ngModel)]="UserInfo.lastname">
                    </div>
                    <div class="login-field">
                        <label for="">NIF (opcional)</label>
                        <input type="text" [(ngModel)]="UserInfo.taxnumber">
                    </div>
                    <div class="login-field">
                        <label for="">Telemóvel *</label>
                        <input type="text" [(ngModel)]="UserInfo.phonenumber" (ngModelChange)="ValidatePhone()">
                        <span *ngIf="UserInfo.phonenumber && !validPhone" class="field-error">Por favor utilize um contacto válido, será enviado um SMS para
                            finalizar o seu registo.</span>
                    </div>
                    <div class="login-field">
                        <label for="">E-mail *</label>
                        <input type="text" [(ngModel)]="UserInfo.email" (ngModelChange)="ValidateEmail()"
                            (focusout)="removeSpaces()" tabindex="0" autocapitalize="off" autocomplete="off"
                            autocorrect="off" spellcheck="false">
                        <span *ngIf="UserInfo.email && !validEmail" class="field-error">O e-mail não
                            é válido</span>
                    </div>
                    <div class="login-field">
                        <label for="">Palavra-passe *</label>
                        <input type="password" [(ngModel)]="UserInfo.password" (ngModelChange)="ValidatePassword()"
                            autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false">
                    </div>
                    <span *ngIf="UserInfo.password && !validPassword" class="field-error">A
                        palavra-passe deve conter pelo menos 8 caracteres, dos quais 1 simbolo e 1 número</span>
                    <div class="login-field button-wrap">
                        <app-generic-button (ClickedChange)="GoBack()" [Label]="'Cancelar'" [CustomClass]="'cancel'">
                        </app-generic-button>
                        <app-generic-button (ClickedChange)="NextStep()"
                            [Disabled]="!UserInfo.firstname || !UserInfo.lastname || !validEmail || !validPassword || !validPhone"
                            [Label]="'Seguinte'"></app-generic-button>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Select address -->
        <ng-container *ngSwitchCase="1">
            <div class="login-form">
                <div class="login-fields">
                    <div class="login-field">
                        <label for="">Cidade *</label>
                        <app-dropdown [Label]="'Selecione uma cidade'" [Items]="CityOptions" [(Field)]="SelectedCity"
                            (FieldChange)="SetSelectedCity($event)"></app-dropdown>
                    </div>
                    <div class="login-field">
                        <label for="">Morada *</label>
                        <input type="text" [(ngModel)]="Address">
                    </div>
                    <div class="half-fields">
                        <div class="login-field door-number">
                            <label for="">Nº da porta *</label>
                            <input type="text" [(ngModel)]="DoorNumber">
                        </div>
                        <div class="login-field floor-number">
                            <label for="">Andar</label>
                            <input type="text" [(ngModel)]="Floor">
                        </div>
                    </div>
                    <div class="login-field">
                        <label for="">Código postal *</label>
                        <input type="text" [(ngModel)]="UserInfo.address.postcode" (ngModelChange)="ValidateZip()">
                        <span *ngIf="UserInfo.address.postcode && !validZip" class="field-error">O código postal deve
                            estar no formato 0000-000</span>
                    </div>
                    <div class="login-field button-wrap">
                        <app-generic-button (ClickedChange)="PreviousStep()" [Label]="'Anterior'"
                            [CustomClass]="'cancel'"></app-generic-button>
                        <app-generic-button (ClickedChange)="getAddressBasedCoordinates(); NextStep()"
                            [IsLoading]="Registering" [Disabled]="!Address || !DoorNumber || !validZip"
                            [Label]="'Seguinte'"></app-generic-button>
                    </div>
                </div>
            </div>
        </ng-container>
         <!-- Map selector -->
         <ng-container *ngSwitchCase="2">
            <div class="login-form">
                <div *ngIf="detectedLatitude && detectedLongitude" class="location-select-wrapper">
                    <span class="info-label">Localize a sua habitação no mapa e pressione no local exato. Um ponto
                        aproximado foi detetado através da morada que inseriu no passo anterior.</span>
                    <agm-map class="map-picker" [zoom]="13" [mapTypeId]="'hybrid'" [latitude]="SelectedCity.lat"
                        [longitude]="SelectedCity.long">
                        <ng-container *ngIf="pickedLatitude && pickedLongitude">
                            <agm-marker [latitude]="pickedLatitude" [longitude]="pickedLongitude">
                            </agm-marker>
                        </ng-container>
                        <ng-container *ngIf="detectedLatitude && detectedLongitude">
                            <agm-marker [latitude]="detectedLatitude" [longitude]="detectedLongitude"
                                [iconUrl]="'../../../assets/media/img/acc.png'">
                            </agm-marker>
                        </ng-container>
                        <ng-container *ngIf="SelectedCity">
                            <agm-circle [latitude]="SelectedCity.lat" [strokeColor]="'#fff'" [fillOpacity]="0.1"
                                [strokeWeight]="2" [longitude]="SelectedCity.long"
                                (circleClick)="SetCoordinates($event)" [radius]="SelectedCity.maxdeliveryrange">
                            </agm-circle>
                        </ng-container>
                    </agm-map>
                </div>
                <div class="login-field button-wrap">
                    <app-generic-button [Label]="'Anterior'" (ClickedChange)="PreviousStep()"
                        [CustomClass]="'cancel'"></app-generic-button>
                    <app-generic-button [Label]="'Seguinte'" [Disabled]="!pickedLatitude || !pickedLongitude"
                        (ClickedChange)="SendRegister(true)"></app-generic-button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <div class="login-form">
                <div class="login-fields">
                    <div class="login-field">
                        <label for="">Código SMS</label>
                        <input type="text" [(ngModel)]="VerificationCode" (ngModelChange)="ValidateCode()">
                    </div>
                    <div class="login-field">
                        <p class="small-link" (click)="Resend()">Reenviar código de confirmação</p>
                    </div>
                    <div class="login-field button-wrap">
                        <app-generic-button (ClickedChange)="SendPhoneValidation()" [Disabled]="!validCode"
                            [Label]="'Validar'"></app-generic-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
   </div>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from 'src/app/services/orders.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-follow-order',
  templateUrl: './follow-order.component.html',
  styleUrls: ['./follow-order.component.scss']
})
export class FollowOrderComponent implements OnInit, OnDestroy {

  public OrderId: number;
  public OrderStatus: number;
  public OrderRef: string;
  public LastUpdate: string;
  public Notes: string;
  public Loading: boolean;
  public ReloadSub: any;

  constructor(private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrdersService) { }

  async ngOnInit() {
    this.Loading = true;
    this.OrderId = this.route.snapshot.params['reference'];
    await this.GetOrderInfo(this.OrderId);
    this.SetAutomaticRefresh();
    this.Loading = false;
  }

  SetAutomaticRefresh() {
    clearInterval(this.ReloadSub);
    this.ReloadSub = setInterval(() => {
      this.GetOrderInfo(this.OrderId);
    }, 10000)
  }

  async GetOrderInfo(id) {
    const response = await this.orderService.getOrderByReference(id);
    this.OrderStatus = response.orderstatusid;
    this.Notes = response.cancelnotes;
    this.OrderRef = response.ordernumber;
    this.LastUpdate = this.LateBy(response.updated_at);
    this.SetAutomaticRefresh();
  }

  LateBy(date) {
    const values = this.utilsService.DateDifference(new Date(), new Date(date));
    let stringToSend = values.toString();
    console.log(values);
    
    if(values > 1440) {
      const value = Math.floor((values / 60) / 24);
      stringToSend = value + (value === 1 ? ' dia' : ' dias');
    } else if (values > 60) {
      const value = Math.floor(values / 60);
      stringToSend = value + (value === 1 ? ' hora' : ' horas');
    } else if (values === 0) {
      stringToSend = ' segundos';
    } else {
      stringToSend = values + ' min';
    }

    return stringToSend;
  }

  goBack() {
    this.router.navigate(['user', 'history']);
  }

  ngOnDestroy() {
    clearInterval(this.ReloadSub);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colaborate',
  templateUrl: './colaborate.component.html',
  styleUrls: ['./colaborate.component.scss']
})
export class ColaborateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-checkout-done',
  templateUrl: './checkout-done.component.html',
  styleUrls: ['./checkout-done.component.scss']
})
export class CheckoutDoneComponent implements OnInit {

  public OrderId: number;

  constructor(private cartService: CartService,
    private route: ActivatedRoute,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.cartService.EmptyCart();
    this.OrderId = this.route.snapshot.params['id'];
  }

  FollowOrder() {
    this.navigationService.NavigateTo({ itemGo: ['track', this.OrderId.toString()] })
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggedUserInfo, UserAddressList, UserContact, UserRegister } from 'src/assets/models/management/user.model';
import { AuthenticationService } from './authentication/authentication.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public currentUserInfo: LoggedUserInfo;
  public LoggedIn: boolean;
  public LoggedInChange: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthenticationService, private http: HttpClient) {
    this.LoggedInChange.subscribe(value => {
      this.LoggedIn = value;
    });

    this.authService.currentUser.subscribe(value => {
      this.currentUserInfo = value;
      this.LoggedInChange.next(value ? true : false);
    });
  }

  BuildHeaders() {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': this.currentUserInfo ? this.currentUserInfo.token_type + ' ' + this.currentUserInfo.access_token : ''
    });
  }

  async Logout() {
    this.authService.logout();
    return await this.http.post<any>(`${environment.baseUrl}logout`, { headers: this.BuildHeaders() }).toPromise();
  }

  async RecoverPassword(email: string) {
    return await this.http.post<any>(`${environment.baseUrl}forget`, { email: email, provider: 'client' }).toPromise();
  }

  async ChangePasswordByToken(email: string, password: string, confirmPassword: string, token: string) {
    return await this.http.post<any>(`${environment.baseUrl}password/reset`, {
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      provider: 'client',
      token: token
    }).toPromise();
  }

  async RegisterUser(UserProfile: UserRegister) {
    return await this.http.post<any>(`${environment.baseUrl}register-user`, UserProfile, { observe: 'response' }).toPromise();
  }

  async ValidateToken() {
    if (this.currentUserInfo) {
      console.log('tried');
      try {
        await this.http.get(`${environment.baseUrl}check-token`, { headers: this.BuildHeaders() }).toPromise();
      } catch (error) {
        this.authService.logout(false);
      }
    }
  }

  async LoginByRegister(email: string, password: string) {
    return this.authService.login(email, password);
  }

  async GetUserAddresses() {
    return await this.http.get<UserAddressList>(`${environment.baseUrl}client-address`, { headers: this.BuildHeaders() }).toPromise();
  }

  async GetUserInfo() {
    return await this.http.get<UserRegister>(`${environment.baseUrl}client`, { headers: this.BuildHeaders() }).toPromise();
  }

  async GetUserHistory() {
    return await this.http.get<any[]>(`${environment.baseUrl}order-client`, { headers: this.BuildHeaders() }).toPromise();
  }

  async saveUserInfo(userInfo) {
    return await this.http.post<UserRegister>(`${environment.baseUrl}update-client-info`, userInfo, { headers: this.BuildHeaders(), observe: 'response' }).toPromise();
  }

  async ValidatePhoneNumber(phoneNumber: number, userCode: string) {
    return await this.http.post(`${environment.baseUrl}validate-account`, { phonenumber: phoneNumber, code: userCode }, { observe: 'response' }).toPromise();
  }

  async ResendPhoneNumber(phoneNumber: number, newNumber?: number) {
    return await this.http.post(`${environment.baseUrl}resend-sms`, { phonenumber: phoneNumber, newnumber: newNumber }, { observe: 'response' }).toPromise();
  }

  async sendContact(userContact: UserContact) {
    return await this.http.post(`${environment.baseUrl}contact-message`, userContact, { observe: 'response' }).toPromise();
  }

  async getCoordinatesByAddress(address: string) {
    return await this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBxyVKY4PAiH_I2m2-srRiR8ZxhPiyom7U
    `, {}).toPromise();
  }



}

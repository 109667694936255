import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { TopnavComponent } from './layout/topnav/topnav.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { OrderingComponent } from './components/ordering/ordering.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { DropdownComponent } from './layout/dropdown/dropdown.component';
import { CardListComponent } from './layout/card-list/card-list.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { SupplierMenusComponent } from './components/supplier-menus/supplier-menus.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { MenuItemComponent } from './components/supplier-menus/menu-item/menu-item.component';
import { QtySelectorComponent } from './components/qty-selector/qty-selector.component';
import { NoPermissionComponent } from './layout/no-permission/no-permission.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { GenericButtonComponent } from './layout/buttons/generic-button/generic-button.component';
import { SmallSpinnerComponent } from './layout/small-spinner/small-spinner.component';
import { ToastComponent } from './layout/toast/toast.component';
import { ToastBoxComponent } from './layout/toast/toast-box/toast-box.component';
import { RegisterComponent } from './components/register/register.component';
import { AgmCoreModule } from '@agm/core';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CartPopupComponent } from './components/cart-popup/cart-popup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AccordionBoxComponent } from './layout/accordion-box/accordion-box.component';
import { ErrorInterceptor } from './services/helpers/error.interceptor';
import { AddressPopupComponent } from './components/address-popup/address-popup.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { CheckoutDoneComponent } from './components/checkout-done/checkout-done.component';
import { ColaborateComponent } from './components/colaborate/colaborate.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { SupplierMenuGroupComponent } from './components/supplier-menu-group/supplier-menu-group.component';
import { FooterComponent } from './components/footer/footer.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { VerifyPhoneComponent } from './components/verify-phone/verify-phone.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { CategoryFiltersComponent } from './components/category-filters/category-filters.component';
import { MenusPageComponent } from './components/menus-page/menus-page.component';
import { FollowOrderComponent } from './components/follow-order/follow-order.component';
import { CookieAcceptComponent } from './components/cookie-accept/cookie-accept.component';
import { UserAddressesComponent } from './components/user-addresses/user-addresses.component';
import { UserHistoryComponent } from './components/user-history/user-history.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { LogoutComponent } from './components/logout/logout.component';
import { UserSupportComponent } from './components/user-support/user-support.component';
import { ChatSupportComponent } from './components/chat-support/chat-support.component';
import { ModalsComponent } from './layout/modals/modals.component';
import { DialogDirective } from './services/dialog.directive';
import { ScheduleModalComponent } from './components/popup-modal/schedule-modal/schedule-modal.component';
import { LocationModalComponent } from './components/popup-modal/location-modal/location-modal.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { StepperWrapperComponent } from './components/stepper-wrapper/stepper-wrapper.component';
import { DropdownModalComponent } from './layout/dropdown-modal/dropdown-modal.component';
import { UserAddressComponent } from './components/user-addresses/user-address/user-address.component';
import { ConfirmationModalComponent } from './components/popup-modal/confirmation-modal/confirmation-modal.component';
import { UserOrderComponent } from './components/user-history/user-order/user-order.component';
import { OrderDetailsModalComponent } from './components/user-history/order-details-modal/order-details-modal.component';
import { SupplierPageComponent } from './components/supplier-page/supplier-page.component';
import { SessionService } from './services/session.service';
import { HelpComponent } from './components/help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    TopnavComponent,
    OrderingComponent,
    SchedulingComponent,
    DropdownComponent,
    CardListComponent,
    SuppliersComponent,
    SupplierMenusComponent,
    PopupModalComponent,
    ShoppingCartComponent,
    MenuItemComponent,
    QtySelectorComponent,
    NoPermissionComponent,
    LoaderComponent,
    LoginComponent,
    RecoverPasswordComponent,
    GenericButtonComponent,
    SmallSpinnerComponent,
    ToastComponent,
    ToastBoxComponent,
    RegisterComponent,
    CheckoutComponent,
    CartPopupComponent,
    ProfileComponent,
    TabItemComponent,
    AccordionBoxComponent,
    AddressPopupComponent,
    HowItWorksComponent,
    NavigationMenuComponent,
    CheckoutDoneComponent,
    ColaborateComponent,
    ContactsComponent,
    PartnersComponent,
    ContactFormComponent,
    SupplierMenuGroupComponent,
    FooterComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    VerifyPhoneComponent,
    TagsInputComponent,
    CategoryFiltersComponent,
    MenusPageComponent,
    FollowOrderComponent,
    CookieAcceptComponent,
    UserAddressesComponent,
    UserHistoryComponent,
    UserInformationComponent,
    UserNotificationsComponent,
    LogoutComponent,
    UserSupportComponent,
    ChatSupportComponent,
    ModalsComponent,
    DialogDirective,
    ScheduleModalComponent,
    LocationModalComponent,
    AddAddressComponent,
    StepperWrapperComponent,
    DropdownModalComponent,
    UserAddressComponent,
    ConfirmationModalComponent,
    UserOrderComponent,
    OrderDetailsModalComponent,
    SupplierPageComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxyVKY4PAiH_I2m2-srRiR8ZxhPiyom7U'
    })
  ],
  providers: [{
    provide: APP_INITIALIZER,
      useFactory: initializeAuthData,
      deps: [SessionService],
      multi: true
   },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeAuthData(sessionService: SessionService) {
  return async () => {
    await sessionService.ValidateToken();
    
  }
}

export class Supplier {
    id: number;
    name: string;
    logo?: string;
    description: string;
    availability: boolean;
    scheduleInfo: ScheduleTime[];
}

export class ScheduleTime {
    opentime: string;
    endtime: string;
    dayoff: string;
    busyhours: string;
    avgtimetocook: string;
    dayofweek: string;
}

export class SupplierParameters {
    start: string;
    end: string;
    city: number;
    category = null;
    category_type: string;
}
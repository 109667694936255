import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { City } from 'src/assets/models/management/city.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent implements OnInit {

  public Cities: City[];
  public CityOptions: DropdownItem[];
  public SelectedCity: City;

  constructor(private supplierService: SuppliersService,
    private locationService: LocationService) { }

  async ngOnInit() {
    this.Cities = await this.supplierService.getCities();
    this.BuildCityOptions(this.Cities);
  }

  BuildCityOptions(cityList) {
    this.CityOptions = [];
    cityList.forEach(city => {
      this.CityOptions.push({ key: city, value: city.city })
    });
  }

  SetSelectedCity(event: City) {
    this.SelectedCity = event;
  }

  ConfirmCity() {
    this.locationService.SetUserCity(event);
  }
  
}

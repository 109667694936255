<app-popup-modal [(OpenModal)]="ShowShoppingCart" (OpenModalChange)="closeCart()">
    <div slot="title">
        Carrinho de compras
    </div>
    <div slot="body">
        <app-shopping-cart></app-shopping-cart>
    </div>
    <ng-container slot="actions">
        <app-generic-button [Label]="'Fechar'" (ClickedChange)="closeCart()" [CustomClass]="'cancel'">
        </app-generic-button>
        <app-generic-button [Label]="'Continuar (' + TotalCartPrice + '€)'"
        [Disabled]="NumOfCartItems === 0" (ClickedChange)="checkoutOrder()"></app-generic-button>
    </ng-container>
</app-popup-modal>
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { Menu, MenuSize } from 'src/assets/models/management/menu.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger(
      'AddLabel', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'scale(0)', opacity: 0 }),
            animate('0.15s ease-out', 
                    style({ transform: 'scale(1.2)', opacity: 1 })),
            animate('0.15s ease-out', 
                    style({ transform: 'scale(1)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ transform: 'scale(1)', opacity: 1 }),
            animate('0.15s ease-in', 
                    style({ transform: 'scale(1.2)', opacity: 1 })),
                    animate('0.15s ease-in', 
                    style({ transform: 'scale(0)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class MenuItemComponent implements OnInit {

  @Input() MenuItem: Menu;
  @Input() Quantity = 1;
  @Input() Id: string;
  @Input() ViewOnly: boolean;
  @Input() DeleteMode: boolean;
  @Input() WriteNotes: boolean;
  @Input() Notes: string;
  @Output() QuantityChange: EventEmitter<number> = new EventEmitter();
  @Output() NotesChange: EventEmitter<string> = new EventEmitter();
  @Output() SizeChange: EventEmitter<number> = new EventEmitter();

  public ShowAddLabel: boolean;
  public ShowNotes: boolean;
  public SelectedSize: MenuSize;
  public SizeItems: DropdownItem[] = [];
  public TrimPrice: string;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
    this.ShowAddLabel = false;

    this.TrimPrice = (Math.round(this.MenuItem.price * 100) / 100).toFixed(2);
    if(this.MenuItem.size) {
      this.BuildSizes();
    }
  }

  AddItemToCart() {
    try {
      this.cartService.AddItem(this.MenuItem, this.Quantity);
      this.ShowAddLabel = true;
      setTimeout(() => {
      this.ShowAddLabel = false;      
      }, 400);
    } catch (error) {
      
    }
  }

  BuildSizes() {
    
    if(this.MenuItem.sizes && this.MenuItem.sizes.length > 0 && !this.MenuItem.sizeid) {
      this.MenuItem.sizeid = this.MenuItem.sizes[0].id;
    }

    this.MenuItem.sizes.forEach(element => {
      this.SizeItems.push({ key: element.id, value: element.name, extra: parseFloat(element.priceincrease.toString()).toFixed(2) + ' €' });
    });
  }

  OpenNotes() {
    this.ShowNotes = !this.ShowNotes;
  }

  EmitQuantityChange(event) {
    this.QuantityChange.emit(event);
  }

  RemoveItemFromCart() {
    this.cartService.RemoveItem(this.Id);
  }

  NoteChanged(notes: string) {
    this.NotesChange.emit(notes);
  }

  EmitSize(event) {
    this.SizeChange.emit(event);
  }

  GetSizePrice() {
    const response = this.MenuItem.sizes.find(size => size.id === this.MenuItem.sizeid);
    
    return response && response.priceincrease ? (Math.round(response.priceincrease * 100) / 100).toFixed(2) : '';
  }

  
  SetDefault(event, target) {
    event.target.src = 'assets/media/defaults/card.jpg';
  }

}

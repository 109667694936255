<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="limit-form-wrapper">
        <h1>Ser parceiro</h1>
        <div class="wrapped-section">
            Gostavas de fazer parte da nossa rede de parceiros?
            <br> <br>
            O Pedir Sem Sair não obriga a uma fidelização, se não ficares satisfeito podes simplesmente abandonar a
            nossa plataforma a qualquer altura.
            <br><br>
            Envia-nos uma mensagem!
        </div>
        <app-contact-form [ContactType]="'Parceiros'"></app-contact-form>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent implements OnInit {

  public isLoading = true;
  public UserHistory: any[];

  constructor(private sessionService: SessionService,
    private utilsService: UtilsService,
    private navigationService: NavigationService) { }

  async ngOnInit() {
    await this.BuildUserHistory();
  }


  async BuildUserHistory() {
    this.isLoading = true;
    try {
      const history = await this.sessionService.GetUserHistory();
      this.UserHistory = history

    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  }

}

<ng-container *ngIf="Loaded">
    <app-navigation-menu></app-navigation-menu>
    <router-outlet></router-outlet>
    <lst-toast></lst-toast>
    <app-cart-popup></app-cart-popup>
    <ng-container *ngIf="!AcceptedCookies">
        <app-cookie-accept></app-cookie-accept>
    </ng-container>
    <div *ngIf="showPopup" [@popupAnimation]>
        <div class="modal-fullwrapper" (click)="closeDialog()">
          <div (click)="$event.stopPropagation();">
            <app-modals></app-modals>
          </div>
        </div>
      </div>
</ng-container>
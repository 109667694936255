<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="limit-form-wrapper">
        <h1>Ser um piloto!</h1>
        <div class="form-wrapper">
            <div class="field-wrapper">
                Se queres fazer parte da nossa equipa, precisas de cumprir os seguintes requisitos:
                <ul>
                    <li>Maior de 18 anos</li>
                    <li>Cartão de condução</li>
                    <li>Motociclo ou automóvel</li>
                </ul>
            </div>
        </div>
        <app-contact-form [ContactType]="'Estafeta'"></app-contact-form>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastMessage } from 'src/assets/models/ui/toast-message.model';

@Component({
  selector: 'dsp-toast-box',
  templateUrl: './toast-box.component.html',
  styleUrls: ['./toast-box.component.scss']
})
export class ToastBoxComponent implements OnInit {

  @Input() ToastMessage: ToastMessage;
  @Output() DismissToastEvent: EventEmitter<ToastMessage> = new EventEmitter();
  public ToastTimer: any;
  public ToastTimerCSS: string;
  
  constructor() { }

  ngOnInit() {
    this.SetToastTimeout();
    this.SetCSSTimer();
  }
  SetCSSTimer() {
    const valueSeconds = this.ToastMessage.timer / 1000;
    this.ToastTimerCSS = valueSeconds + 's';
  }
  SetToastTimeout() {
    if(this.ToastMessage.timer > 0) {
      this.ToastTimer = setTimeout(() => {
        this.SendDismissCall();
      }, this.ToastMessage.timer);
    }
  }

  DismissMessage() {
    clearTimeout(this.ToastTimer);
    this.SendDismissCall();
  }

  SendDismissCall() {
    this.DismissToastEvent.emit(this.ToastMessage);
  }

}

import { style, animate, transition, trigger, query, stagger, keyframes } from '@angular/animations';

export const popupAnimation =  trigger('popupAnimation', [
  transition(':enter', [
    query('.modal-body', [
      style({opacity: 0, transform: 'scale(0)'}),
      stagger(0, [
        animate('0.45s', keyframes([
          style({ opacity: 1, transform: 'scale(0)' }),
          style({ opacity: 1, transform: 'scale(1.2)' }),
          style({ opacity: 1, transform: 'scale(1)' })

        ]))
      ])
    ])
  ]),
  transition(':leave', [
    query('.modal-body', [
      style({opacity: 1, transform: 'scale(1)'}),
      stagger(0, [
        animate('0.45s', keyframes([
          style({ opacity: 1, transform: 'scale(1)' }),
          style({ opacity: 1, transform: 'scale(1.2)' }),
          style({ opacity: 1, transform: 'scale(0)' })
        ]))
      ])
    ])
  ])
]);
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { DropdownModalComponent } from '../dropdown-modal/dropdown-modal.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger(
      'PopDropdown',
      [
        transition(
          ':enter',
          [
            style({ maxHeight: 0, opacity: 0 }),
            animate('0.15s ease-out',
              style({ maxHeight: 200, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ maxHeight: 200, opacity: 1 }),
            animate('0.15s ease-in',
              style({ maxHeight: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DropdownComponent implements OnInit {

  @Input() Label: string;
  @Input() Field: any;
  @Input() Items: DropdownItem[];
  @Input() Mandatory: boolean;
  @Input() Disabled = false;
  @Input() Clearable = false;
  @Output() FieldChange = new EventEmitter<any>();
  @Output() FieldHasErrorsChange = new EventEmitter<boolean>();
  @Output() OnClickEvent = new EventEmitter<any>();

  public DropdownState: boolean = true;
  public DropdownLabel: string;
  @ViewChild('dropdownWrapper', { static: false }) dropdownWrapper: ElementRef;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    setTimeout(() => {
      this.SetDropdownLabel();
      this.ValidateField();
    }, 150);
  }

  ngOnChanges(changes: any) {
    if (changes.Items || changes.Field) {
      this.SetDropdownLabel();
      this.ValidateField();
    }
  }

  ValidateField() {
    if (this.Field === null || this.Field === undefined) {
      this.FieldHasErrorsChange.emit(true);
    } else {
      this.FieldHasErrorsChange.emit(false);
    }
  }

  HideDropdown(condition: boolean) {
    // this.DropdownState = condition;
    this.modalService.openDialog({ component: DropdownModalComponent, data: { options: this.Items, selection: this.Field } }).subscribe(data => {
      if (data.action) {
        this.Field = data.data;
        this.FieldChange.emit(data.data);
      }
    })

    if (!this.DropdownState) {
      this.OnClickEvent.emit(true);
    }
  }

  SetValue(key) {
    this.Field = key;
    this.SetDropdownLabel();
    this.dropdownWrapper.nativeElement.blur();
    this.FieldChange.emit(key);
    this.FieldHasErrorsChange.emit(false);
  }

  ClearField() {
    this.FieldChange.emit(null);
    this.FieldHasErrorsChange.emit(true);
    this.dropdownWrapper.nativeElement.blur();
  }

  SetDropdownLabel() {
    if (this.Items) {
      const item = this.Items.find(item => item.key === this.Field);
      if (item != null && item != undefined) {
        this.DropdownLabel = item.value;
      }
    }
  }

  EmitChanges(changes: any) {
    this.FieldChange.emit(changes);
  }
}

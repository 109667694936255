import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class StepItem {
  name: string;
}

@Component({
  selector: 'app-stepper-wrapper',
  templateUrl: './stepper-wrapper.component.html',
  styleUrls: ['./stepper-wrapper.component.scss']
})
export class StepperWrapperComponent implements OnInit {

  @Input() steps: StepItem[] = [];
  @Input() selectedStep: number;
  @Output() selectedStepChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="limit-form-wrapper">
    <h2 class="user-title">Moradas</h2>
    <label class="info-label">
        Uma vez definida uma morada, não é possível alterar os dados da mesma.
        No entanto, pode criar uma nova morada e marcar a mesma como principal.
    </label>
    <ng-container *ngIf="isLoading">
        <div class="loading">
            <app-loader></app-loader>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="addresses">
            <ng-container *ngFor="let address of addresses.addresses; let index = index">
                <app-user-address [userAddress]="address" [cities]="cityOptions"
                    [isDefault]="address.id === addresses.defaultaddressid"
                    (setDefaultChange)="openDefaultPrompt($event)"></app-user-address>
            </ng-container>
            <div class="new-address-wrapper" (click)="addNewAddress()">
                <span class="regular-16-svg" [inlineSVG]="'../../../assets/media/img/plus.svg'"></span>
            </div>
        </ng-container>
    </ng-container>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggedUserInfo } from 'src/assets/models/management/user.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    private currentUserSubject: BehaviorSubject<LoggedUserInfo>;
    public currentUser: Observable<LoggedUserInfo>;

    constructor(private http: HttpClient,
        private route: Router) {
        this.currentUserSubject = new BehaviorSubject<LoggedUserInfo>(JSON.parse(localStorage.getItem('PD-USER')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): LoggedUserInfo {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        
        const url = `${environment.baseUrl}login`;
        const body = {
            grant_type: 'password',
            client_id: '2',
            client_secret: 'cDEt27qAMCSFtXUQTNOyvIwoJ5hz9XJXnYrtl4Wn',
            username: username,
            password: password,
            client_scope: '*',
            provider: 'client'
            };
        const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        });
        return this.http.post<any>(url, body, {headers: headers})
            .pipe(map(user => {
                localStorage.setItem('PD-USER', JSON.stringify(user));
                console.log('success');
                
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout(redirect = true) {
        localStorage.removeItem('PD-USER');
        this.currentUserSubject.next(null);
        if(redirect) {
            this.route.navigate(['home']);
        }
    }
}
export class ToastMessage {
    id: string;
    type: ToastType;
    message: string;
    dismissed: boolean = false;
    timer: number = 2500;
}

export enum ToastType {
    Regular = 0,
    Success,
    Error,
    Warning
}
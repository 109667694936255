<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="limit-form-wrapper">
        <div *ngIf="!isLoading" class="supplier-page-wrapper">
            <div class="back-option-wrapper" (click)="goBack()">
                  <span class="arrow left"></span> <label>{{ supplierName || 'Voltar à lista' }}</label>
            </div>
            <div class="supplier-category-filter">
                <div class="supplier-category" [class.selected]="!selectedCategory" (click)="selectCategory(null)">
                    Todas
                </div>
                <div *ngFor="let category of menuByTypes | keyvalue" class="supplier-category"
                    [class.selected]="selectedCategory == category.key" (click)="selectCategory(category.key)">
                    {{ category.key }}
                </div>
            </div>
            <div *ngFor="let category of menuByTypes | keyvalue" class="supplier-category-wrapper">
                <ng-container *ngIf="!selectedCategory || selectedCategory == category.key">
                    <h2>
                            <span class="dash"></span>
                            <div class="supplier-category-name">{{ category.key }}</div>
                            <span class="dash"></span>
                    </h2>
                    <div class="supplier-menus-wrapper">
                    <div *ngFor="let menu of category.value" class="supplier-menu-wrapper">
                            <app-menu-item [MenuItem]="menu"></app-menu-item>
                        </div>
                    </div>
            </ng-container>
            </div>
        </div>
        <div *ngIf="isLoading" class="loading">
            <app-loader></app-loader>
        </div>
    </div>

</div>
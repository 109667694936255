<div #supplierWrapper class="suppliers-wrapper">
    <div>
        <app-category-filters [CategoryType]="SupplierParameters.category_type"></app-category-filters>
    </div>
    <div *ngIf="!Loading" class="supplier-list-wrapper">
        <div *ngFor="let supplier of Suppliers" [@inOutAnimation] (click)="supplier.availability ? OpenSupplierModal(supplier) : null"
        class="supplier-wrapper" [class.closed]="!supplier.availability">
            <div class="supplier-img">
                <img [src]="supplier.logo ? supplier.logo : '../../../assets/media/defaults/card.jpg'" (error)="SetDefault($event, supplier.logo)" alt="">
            </div>
            <div class="supplier-info">
                <div class="supplier-title">
                    {{ supplier.name }}
                </div>
                <div class="supplier-description">
                    {{ supplier.description }}
                </div>
                <div class="supplier-time">
                    <span class="time-title">Horário</span>
                    <ng-container>
                        <div *ngFor="let time of supplier.scheduleInfo" class="time-wrapper">
                            <ng-container *ngIf="DayOfWeek == time.dayofweek">
                                {{ time.opentime + ' - ' + time.endtime }}
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!supplier.availability">
                        <div class="supplier-closed">Encerrado</div>
                    </ng-container>
                </div>
                <div class="supplier-rating">
                    <!-- <div class="rating">
                        <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="Suppliers.length === 0">
            <div class="no-results-wrapper">
               Sem resultados
            </div>
        </div>
    </div>
    <div *ngIf="Loading" class="loading">
        <app-loader></app-loader>
    </div>
</div>
<ng-container *ngIf="SelectedSupplier">
    <app-popup-modal [(OpenModal)]="ShowSupplierModal">
        <div slot="title">
            Menus <span *ngIf="!SelectedSupplier.availability" class="supplier-closed">(encerrado)</span>
        </div>
        <div slot="body">
            <app-supplier-menus [Supplier]="SelectedSupplier"></app-supplier-menus>
        </div>
        <div slot="actions">
            <button [disabled]="NumOfCartItems === 0" (click)="CheckoutOrder()" class="regular-button green">
                Finalizar ({{ TotalCartPrice }} €)
            </button>
        </div>
    </app-popup-modal>

</ng-container>
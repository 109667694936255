<div class="shopping-cart-wrapper">
    <div class="cart-list-wrapper">
        <div *ngFor="let item of Items" class="cart-item-wrapper">
            <app-menu-item [MenuItem]="item.menu"
            [(Quantity)]="item.qty"
            [Id]="item.id"
            [DeleteMode]="true"
            [WriteNotes]="false"
            (SizeChange)="SaveChanges()"
            (QuantityChange)="SaveChanges()"></app-menu-item>
        </div>
        <div *ngIf="!Items || Items.length === 0" class="shopping-cart-empty">
            Não tem produtos no seu carrinho.
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';
import { ToastType } from 'src/assets/models/ui/toast-message.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public Username: string;
  public Password: string;
  public LoggingIn: boolean;

  constructor(private authService: AuthenticationService, 
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private toastService: ToastService) { }

  ngOnInit(): void {
  }

  async Login() {
    this.LoggingIn = true;

    try {
      await this.authService.login(this.Username, this.Password).toPromise();
      this.toastService.AddToast(ToastType.Success, 'Sucesso, a redirecionar...', 1000);
      setTimeout(() => {
        const goTo = new NavItem();
        goTo.itemGo = ['home'];
        if(this.route.snapshot.params['redirect']) {
          goTo.itemGo = [this.route.snapshot.params['redirect']];
        }
        this.navigationService.NavigateTo(goTo);
      }, 1000);
    } catch (error) {
      this.toastService.AddWarningToast('Login falhou, tente novamente...');
    }
    this.LoggingIn = false;
  }

  RecoverPassword() {
    const goTo = new NavItem();
    goTo.itemGo = ['recover-password'];
    this.navigationService.NavigateTo(goTo);
  }

  CreateAccount() {
    const goTo = new NavItem();
    goTo.itemGo = ['register'];
    this.navigationService.NavigateTo(goTo);
  }

  removeSpaces() {
    if(this.Username) {
      this.Username = this.Username.replace(/ /g, '');
    }
  }

}

<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="login-wrapper">
    <div class="login-form">
        <h2 class="title">Iniciar sessão</h2>
        <div class="login-fields">
            <div class="login-field">
                <label for="">E-mail ou telemóvel</label>
                <input id="user-email" type="text" [(ngModel)]="Username" (focusout)="removeSpaces()" tabindex="0"
                autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false">
            </div>
            <div class="login-field">
                <label for="">Palavra-passe</label>
                <input id="user-password" type="password" [(ngModel)]="Password" (keypress)="$event.keyCode === 13 ? Login() : null">
            </div>
            <div class="login-field password-recover">
                <p class="small-link" (click)="RecoverPassword()">Recuperar a palavra-passe</p>
            </div>
            <div class="login-field option-wrapper">
                <app-generic-button
                [IsLoading]="LoggingIn"
                [Disabled]="!Username || !Password"
                (ClickedChange)="Login()" 
                [Label]="'Entrar'"></app-generic-button>
            </div>
            <div class="login-field or-wrapper">
                ou
            </div>
            <div class="login-field">
                <app-generic-button
                [CustomClass]="'cancel'"
                (ClickedChange)="CreateAccount()" 
                [Label]="'Criar uma conta'"></app-generic-button>
            </div>
        </div>
    </div>
</div>
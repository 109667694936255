import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ToastService } from 'src/app/services/toast.service';
import { City } from 'src/assets/models/management/city.model';
import { UserRegister } from 'src/assets/models/management/user.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public UserInfo: UserRegister = new UserRegister();
  public CurrentStep = 0;
  public Cities: City[];
  public CityOptions: DropdownItem[];
  public SelectedCity: City;
  public steps = [{ name: 'Dados' }, { name: 'Morada' }, { name: 'Mapa' }, { name: 'Validar' }]
  /* Address */
  public Address: string;
  public DoorNumber: string;
  public Floor: string;
  /* Coords */
  public pickedLatitude: number;
  public pickedLongitude: number;
  /* Detected */
  public detectedLatitude: number;
  public detectedLongitude: number;
  /* */
  public VerificationCode: string;
  public ShowMapPopup: boolean;
  public Registering: boolean;
  validEmail: boolean;
  validZip: boolean;
  validPassword: boolean;
  validPhone: boolean;
  validCode: boolean;


  constructor(private supplierService: SuppliersService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private sessionService: SessionService) { }

  async ngOnInit() {
    this.Cities = await this.supplierService.getCities();
    this.BuildCityOptions(this.Cities);
  }

  PreviousStep() {
    this.CurrentStep > 0 ? this.CurrentStep-- : null;
  }

  NextStep() {
    this.CurrentStep < this.steps.length ? this.CurrentStep++ : null;
  }

  SetCoordinates(mapClick: any) {
    if (mapClick && mapClick.coords && mapClick.coords.lat && mapClick.coords.lng) {
      this.pickedLatitude = parseFloat(mapClick.coords.lat.toFixed(8));
      this.pickedLongitude = parseFloat(mapClick.coords.lng.toFixed(8));
    }
  }

  BuildCityOptions(cityList) {
    this.CityOptions = [];
    cityList.forEach(city => {
      this.CityOptions.push({ key: city, value: city.city })
    });
  }

  async SendRegister(registerSMS: boolean) {
    this.Registering = true;
    this.UserInfo.sendcode = registerSMS;
    
    this.UserInfo.address.lat = this.pickedLatitude;
    this.UserInfo.address.long = this.pickedLongitude;
    
    this.UserInfo.confirm_password = this.UserInfo.password;
    this.UserInfo.address.streetname = this.Address + ', ' + this.DoorNumber + (this.Floor ? ', ' + this.Floor : '');

    try {
      const response = await this.sessionService.RegisterUser(this.UserInfo);

      if (registerSMS) {
        this.NextStep();
      } else {
        setTimeout(async () => {
          this.toastService.AddSuccessToast('Registo efetuado com sucesso, aguarde...');
          const goTo = new NavItem();
          goTo.itemGo = ['login'];
          this.navigationService.NavigateTo(goTo);
        }, 1500);
      }

    } catch (error) {
      console.log(error);

      if (error.status === 409) {
        this.toastService.AddWarningToast('O e-mail ' + this.UserInfo.email + ' já se encontra registado');
      } else if (error.status === 403) {
        this.toastService.AddWarningToast('O contacto ' + this.UserInfo.phonenumber + ' já se encontra registado');
      }
      else {
        this.toastService.AddErrorToast('Ocorreu um erro, por favor tente mais tarde');
      }
      setTimeout(() => {
        this.Registering = false;
      }, 250);
    }
  }

  async SendPhoneValidation() {
    this.Registering = true;
    try {
      const response = await this.sessionService.ValidatePhoneNumber(this.UserInfo.phonenumber, this.VerificationCode);
      this.toastService.AddSuccessToast('A sua conta foi registada e verificada com sucesso.');
      setTimeout(async () => {
        const goTo = new NavItem();
        goTo.itemGo = ['login'];
        this.navigationService.NavigateTo(goTo);
      }, 1500);
    } catch (error) {
      console.log(error);

      if (error.status === 409) {
        this.toastService.AddWarningToast('O e-mail ' + this.UserInfo.email + ' já se encontra registado');
      } else {
        this.toastService.AddErrorToast('Ocorreu um erro, por favor tente mais tarde');
      }
      setTimeout(() => {
        this.Registering = false;
      }, 250);
    }
  }

  SetSelectedCity(event: City) {
    this.SelectedCity = event;
    this.UserInfo.address.cityid = event.id;
  }

  async getAddressBasedCoordinates() {
    const response = await this.sessionService.getCoordinatesByAddress(this.Address + ', ' + this.UserInfo.address.postcode + ' ' + this.SelectedCity.city);
    console.log(response);

    if (response.results && response.results[0] && response.results[0].geometry && response.results[0].geometry.location) {
      this.detectedLatitude = response.results[0].geometry.location.lat;
      this.detectedLongitude = response.results[0].geometry.location.lng;
    }
  }

  async Resend() {
    if (this.validPhone) {
      try {
        await this.sessionService.ResendPhoneNumber(this.UserInfo.phonenumber);
        this.toastService.AddSuccessToast('Um novo código foi enviado para o seu telemóvel, aguarde')
      } catch (error) {
        if (error.status === 400) {
          this.toastService.AddWarningToast('Já enviou um código há menos de cinco minutos, por favor aguarde...');
        }
      }
    } else {
      this.toastService.AddWarningToast('O número que inseriu não é válido');
    }
  }

  ValidateEmail() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.validEmail = re.test(String(this.UserInfo.email).toLowerCase());
  }

  ValidateZip() {
    const re = /^\d{4}-\d{3}?$/;
    this.validZip = re.test(String(this.UserInfo.address.postcode).toLowerCase());
  }

  ValidatePhone() {
    const re = /^\d{9}$/;
    this.validPhone = re.test(String(this.UserInfo.phonenumber).toLowerCase());
  }

  ValidateCode() {
    const re = /^\d{4}$/;
    this.validCode = re.test(String(this.VerificationCode).toLowerCase());
  }

  ValidatePassword() {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!.,%*#?&])[A-Za-z\d@$!%*.,#?&]{8,}$/;
    this.validPassword = re.test(String(this.UserInfo.password).toLowerCase());
  }

  GoBack() {
    const goTo = new NavItem();
    goTo.itemGo = ['login'];
    this.navigationService.NavigateTo(goTo);
  }

  removeSpaces() {
    if(this.UserInfo.email) {
      this.UserInfo.email = this.UserInfo.email.replace(/ /g, '');
    }
  }

}

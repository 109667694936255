<div class="limit-form-wrapper">
    <h2 class="user-title">Encomendas</h2>
    <ng-container *ngIf="isLoading">
        <div class="loading">
            <app-loader></app-loader>
        </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div class="user-history-wrapper">
            <label class="info-label">
                Verifique o histórico e acompanhe o estado de todas as suas encomendas em tempo real.
            </label>
            <div *ngFor="let history of UserHistory; let index = index" class="user-order-wrapper">
                <app-user-order [order]="history"></app-user-order>
            </div>
            <div *ngIf="!UserHistory || UserHistory.length === 0" class="no-results-wrapper">
                Não há encomendas
            </div>
        </div>
    </ng-container>
</div>
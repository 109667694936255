import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { City } from 'src/assets/models/management/city.model';
import { CategoryType } from 'src/assets/models/management/menu.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { SuppliersService } from './suppliers.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  CurrentCity: DropdownItem;
  CurrentCityChange: Subject<DropdownItem> = new Subject<DropdownItem>();

  Cities: City[];
  CitiesChange: Subject<City[]> = new Subject<City[]>();

  CategoryTypes: CategoryType[];
  CategoryTypesChange: Subject<CategoryType[]> = new Subject<CategoryType[]>();

  constructor(private supplierService: SuppliersService) {

    this.CitiesChange.subscribe(value => {
      this.Cities = value;
    });

    this.CategoryTypesChange.subscribe(value => {
      this.CategoryTypes = value;
    });
   }

  getUserPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(response => {

          resolve(response);
        },
        err => {
        });
    });

  }

  BuildCoordinates(object) {
    return object['latitude'] + ', ' + object['longitude'];
  }

  SetUserCity(city) {
    this.CurrentCity = city;
    sessionStorage.setItem('PD-CITY', JSON.stringify(city));
    this.CurrentCityChange.next(city);
  }

  async BuildCityList() {
    const cities = await this.supplierService.getCities();
    this.CitiesChange.next(cities);
  }

  async BuildCategoryTypes() {
    const categoryTypes = await this.supplierService.getCategoryTypes();
    this.CategoryTypesChange.next(categoryTypes);
  }


   BuildCities() {
    const citiesList = [];
    if(this.Cities) {
      this.Cities.forEach(city => {
        citiesList.push({ key: city.id, value: city.city })
     });
    }
    return citiesList;
  }

}

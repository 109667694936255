import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { City } from 'src/assets/models/management/city.model';
import { UserAddress } from 'src/assets/models/management/user.model';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.scss']
})
export class AddressPopupComponent implements OnInit {

  @Input() ShowAddressPopup: boolean;
  @Output() ShowAddressPopupChange: EventEmitter<boolean> = new EventEmitter();
  @Input() City: City;
  @Input() Address: UserAddress;
  @Output() AddressChange: EventEmitter<UserAddress> = new EventEmitter();

  public PickedLatitude: number;
  public PickedLongitude: number;

    /* Detected */
    public DetectedLatitude: number;
    public DetectedLongitude: number;

  public NewAddress = new UserAddress();

  constructor(private locationService: LocationService) { }

  async ngOnInit() {
    this.City = this.locationService.Cities.find(city => city.id === this.Address.cityid);
    
    if(this.Address.lat && this.Address.long) {
      this.PickedLatitude = parseFloat(this.Address.lat);
      this.PickedLongitude = parseFloat(this.Address.long);
    }
  }

  ClosePopup() {
    this.ShowAddressPopupChange.emit(false);
  }

  SetCoordinates(mapClick: any) {
    if(mapClick && mapClick.coords && mapClick.coords.lat && mapClick.coords.lng) {
      this.PickedLatitude = parseFloat(mapClick.coords.lat.toFixed(8));
      this.PickedLongitude = parseFloat(mapClick.coords.lng.toFixed(8));
    }
  }

  ConfirmCoordinates() {
    this.Address.lat = this.PickedLatitude;
    this.Address.long = this.PickedLongitude;
    this.ShowAddressPopupChange.next(false);
  }


}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DialogItem } from '../layout/modals/modals.component';

export class DialogData {
  action!: boolean;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public activeModal: BehaviorSubject<DialogItem | undefined> = new BehaviorSubject<DialogItem | undefined>(undefined);
  public activeModalChange: Subject<DialogData> = new Subject();

  constructor() {}

  openDialog(dialogComponent: DialogItem) {
    this.activeModal.next(dialogComponent);
    this.activeModalChange = new Subject();
    return this.activeModalChange;
  }

  closeDialog(dialogData = new DialogData()) {
    this.activeModal.next(undefined);
    this.activeModalChange.next(dialogData);
  }
}

<div class="generic-button-wrapper">
    <div *ngIf="ShowConfirmation" class="confirm-wrapper">
        <span class="confirm-label">Tem a certeza?</span>
        <div class="confirm-options-wrapper">
            <button class="regular-button green" (click)="ClickedSend($event)">
                <label>Sim</label>
            </button>
            <button class="regular-button green" (click)="CloseConfirm()">
                <label>Não</label>
            </button>
        </div>
    </div>
    <button *ngIf="!ShowConfirmation || !Confirm" [disabled]="Disabled || IsLoading"
    [ngClass]="CustomClass"
    class="regular-button green" (click)="Confirm ? ConfirmClick() : ClickedSend($event)">
        <app-small-spinner [Loading]="IsLoading"></app-small-spinner>
        <label *ngIf="!IsLoading">{{ Label }}</label>
    </button>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OrdersService } from 'src/app/services/orders.service';
import { SessionService } from 'src/app/services/session.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ToastService } from 'src/app/services/toast.service';
import { Order, OrderConfirmationResult } from 'src/assets/models/management/order.model';
import { UserAddressList } from 'src/assets/models/management/user.model';
import { CartItem } from 'src/assets/models/ui/cart-item.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';
import { ToastType } from 'src/assets/models/ui/toast-message.model';
import { ConfirmationModalComponent } from '../popup-modal/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  public Loading: boolean;
  public PageLoader: boolean;
  public NumOfCartItems: number;
  public TotalCartPrice: string;
  public OrderPrice: string;
  public DifferentSuppliers: number;
  public AddressCost: any;
  public PackageCost: any;
  public Items: CartItem[];
  public ItemsBySupplier = {};
  public UserAddresses: UserAddressList;
  public UserAddressChoices: DropdownItem[] = [];
  public SelectedAddress: number;
  public PaymentType = 1;
  public PaymentQuantity: number;
  public OrderNotes = '';
  public CartSubscription: any;
  public DeliveryHour: string;
  public useTax: boolean;
  public UserTaxNumber: string;
  public LateTax: string;
  public IsBusy: boolean;

  constructor(private shoppingCartService: CartService,
    private navigationService: NavigationService,
    private orderService: OrdersService,
    public supplierService: SuppliersService,
    private toastService: ToastService,
    private modalService: ModalService,
    private sessionService: SessionService) { }

  async ngOnInit() {
    this.PageLoader = true;
    this.VerifyCartContent();
    this.UserAddresses = await this.sessionService.GetUserAddresses();
    await this.CheckAddresses();

    this.UserAddresses.addresses.forEach(address => {
      this.UserAddressChoices.push({ key: address.id, value: (address.streetname || 'Sem nome') });
    });

    this.ShowDeliveryHour();

  }

  ShowDeliveryHour() {
    const date = new Date(this.supplierService.Parameters.start);
    const datePlus = new Date(date);
    datePlus.setMinutes(date.getMinutes() + 30)

    const today = new Date();
    const todayNumber = date.getDate();
    const hourSliced = ('0' + date.getHours()).slice(-2);
    const minuteSliced = ('0' + date.getMinutes()).slice(-2);
    const timeRole = hourSliced + ':' + minuteSliced;

    const hourSlicedNext = ('0' + datePlus.getHours()).slice(-2);
    const minuteSlicedNext = ('0' + datePlus.getMinutes()).slice(-2);
    const timeRoleNext = hourSlicedNext + ':' + minuteSlicedNext;

    if (date.getDate() === today.getDate()) {
      this.DeliveryHour = 'Hoje entre as ' + timeRole + ' e ' + timeRoleNext; 
    } else {
      this.DeliveryHour = 'Amanhã entre as ' + timeRole + ' e ' + timeRoleNext; 
    }
  }

  async BuildItemsBySupplier() {
    this.ItemsBySupplier = {};
    this.Items.forEach(menu => {
      if (!this.ItemsBySupplier[menu.menu.suppliername]) {
        this.ItemsBySupplier[menu.menu.suppliername] = [];
      }

      this.ItemsBySupplier[menu.menu.suppliername].push(menu);
    });
  }

  SaveChanges() {
    this.shoppingCartService.SaveCartInfo();
  }

  async CheckAddresses() {

    if (!this.UserAddresses.addresses || this.UserAddresses.addresses.length === 0) {
      this.toastService.AddSuccessToast('Por favor registe pelo menos uma morada antes de continuar.');
      setTimeout(() => {
        const goTo = new NavItem();
        goTo.itemGo = ['user', 'addresses'];
        this.navigationService.NavigateTo(goTo);
      }, 250);
      return;
    }
    else if (!this.UserAddresses.addresses.find(address => address.cityid === this.supplierService.Parameters.city)) {

      this.toastService.AddToast(ToastType.Warning, 'Não possui moradas para a cidade selecionada, por favor adicione uma.', 2500);
      setTimeout(() => {
        const goTo = new NavItem();
        goTo.itemGo = ['profile'];
        this.navigationService.NavigateTo(goTo);
      }, 250);
      return;
    }
    else {
      this.SelectedAddress = this.UserAddresses.defaultaddressid ? this.UserAddresses.defaultaddressid : this.UserAddresses.addresses[0].id;

      const response: OrderConfirmationResult = await this.OrderConfirmation(this.SelectedAddress, this.shoppingCartService.CartItems);

      if (response) {
        this.shoppingCartService.CartItems = response.menuitems;
        this.Items = this.shoppingCartService.CartItems;
        this.BuildItemsBySupplier();
        this.TotalCartPrice = parseFloat(response.totalprice.toString()).toFixed(2);
        this.OrderPrice = parseFloat(response.orderprice.toString()).toFixed(2);
        this.DifferentSuppliers = response.deliveryquantity;
        this.AddressCost = parseFloat((response.nightordertax ? response.deliveryprice - response.nightordertax : response.deliveryprice).toString()).toFixed(2);
        if(response.nightordertax) {
         this.LateTax = parseFloat(response.nightordertax.toString()).toFixed(2);
        }
        this.IsBusy = response.isbusyhour ? true : false;
        this.UserTaxNumber = response.taxnumber;
        this.shoppingCartService.SaveCartInfo(false);
        this.AddressCost -= response.packagetax;
        this.PackageCost = parseFloat(response.packagetax + '').toFixed(2);

        this.CartSubscription = this.shoppingCartService.CartItemsChange.subscribe(async value => {
          this.Loading = true;
          const response = await this.OrderConfirmation(this.SelectedAddress, value);
          if (response) {
            this.shoppingCartService.CartItems = response.menuitems;
            this.VerifyCartContent();
            this.Items = this.shoppingCartService.CartItems;
            this.BuildItemsBySupplier();
            this.TotalCartPrice = parseFloat(response.totalprice).toFixed(2);
            this.OrderPrice = parseFloat(response.orderprice).toFixed(2);
            this.DifferentSuppliers = response.deliveryquantity;
            this.AddressCost = parseFloat(response.deliveryprice).toFixed(2);
            this.AddressCost -= response.packagetax;
            this.PackageCost = parseFloat(response.packagetax).toFixed(2);
            this.Loading = false;
          }
        });

        this.PageLoader = false;

      } else {
        this.PageLoader = true;
      }
    }
  }

  VerifyCartContent() {
    if (this.shoppingCartService.CartItems.length === 0) {
      const goTo = new NavItem();
      goTo.itemGo = ['home'];
      this.navigationService.NavigateTo(goTo);
      return;
    }
  }

  async OrderConfirmation(addressId: number, cartItems: CartItem[]) {
    try {
      const result = await this.shoppingCartService.getAddressDeliveryCost({
        addressid: addressId,
        menuitems: cartItems,
        deliverytime: this.supplierService.Parameters.start
      });

      return result.body;
    } catch (error) {
      if (error.status === 409) {
        this.toastService.AddToast(ToastType.Error, 'As horas que selecionou já não se encontram válidas, por favor inicie um novo pedido.', 4500);
        setTimeout(() => {
          const goTo = new NavItem();
          goTo.itemGo = ['home'];
          this.shoppingCartService.EmptyCart();
          this.supplierService.SetDeliveryHours(null);
          this.navigationService.NavigateTo(goTo);
        }, 250);
      } else if (error.status === 403) {
        this.toastService.AddToast(ToastType.Warning, 'Para terminar o pedido é necessário validar o seu contacto.', 4500);
        setTimeout(() => {
          const goTo = new NavItem();
          goTo.itemGo = ['verify-phone', 'checkout'];
          this.navigationService.NavigateTo(goTo);
        }, 250);
      } else if (error.status === 406) {
        const suppliername = error.error;        
        this.toastService.AddToast(ToastType.Warning, `Encontra-se demasiado longe de "${suppliername}" para o serviço de entrega.`, 4500);
        setTimeout(() => {
          const goTo = new NavItem();
          goTo.itemGo = ['home'];
          this.navigationService.NavigateTo(goTo);
        }, 250);
      } else {
        this.toastService.AddToast(ToastType.Error, 'Ocorreu um erro, tente novamente...', 4500);
        setTimeout(() => {
          const goTo = new NavItem();
          goTo.itemGo = ['home', 'checkout'];
          this.navigationService.NavigateTo(goTo);
        }, 250);
      }
      return null;
    }
  }

  async ApplyNewAddress() {
    this.SaveChanges();
  }

  confirmOrderModal() {
    this.modalService.openDialog({ component: ConfirmationModalComponent, data: {
      description: 'Tem a certeza que quer continuar?',
      confirmation: 'Continuar',
      cancel: 'Cancelar'
    } }).subscribe(result => {
      if(result.action) {
        this.FinishOrder();
      }
    })
  }

  async FinishOrder() {
    const order = new Order();
    order.addressid = this.SelectedAddress;
    order.menuitems = this.Items;
    order.notes = this.OrderNotes;
    order.paymentid = this.PaymentType;
    order.deliverytime = this.supplierService.Parameters.start;
    order.paymentvalue = this.PaymentQuantity;
    order.cityid = this.supplierService.Parameters.city;
    if(this.useTax) {
      order.taxnumber = this.UserTaxNumber;
    }
    this.PageLoader = true;

    try {
      const orderId = await this.orderService.createOrder(order);
      this.toastService.AddSuccessToast('O sua encomenda foi efetuada com sucesso.');
      setTimeout(() => {
        const goTo = new NavItem();
        goTo.itemGo = ['checkout', 'success', orderId.toString()];
        this.navigationService.NavigateTo(goTo);
      }, 500);
    } catch (error) {
      this.toastService.AddErrorToast('Ocorreu um erro ao processar a sua encomenda.');
    }
  }

  SelectPaymentType(type) {
    this.PaymentType = type;
  }

  ValidatePaymentQuantity(quantity) {
    const re2 = /^\d*\.?\d*$/;
    return re2.test(String(quantity).toLowerCase()) && parseFloat(this.TotalCartPrice) <= this.PaymentQuantity;
  }

  SetTax(bool) {
    this.useTax = bool;
  }

  ngOnDestroy() {
    if (this.CartSubscription) {
      this.CartSubscription.unsubscribe();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public InsertNewCredentials: boolean;
  public LoadingData: boolean;
  public SentCodeSuccess: boolean;
  public UserEmail: string;
  public NewPassword: string;
  public NewPasswordConfirmation: string;
  public UserToken: string;

  constructor(private sessionService: SessionService,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.InsertNewCredentials = false;

    if(this.router.snapshot.params.token) {
      this.InsertNewCredentials = true;
      this.UserToken = this.router.snapshot.params.token;
      
      this.router.queryParamMap.subscribe(value => {
        this.UserEmail = value.get('email');
      });
      
    }
  }

  async SendEmail() {
    this.LoadingData = true;
    try {
      const result = await this.sessionService.RecoverPassword(this.UserEmail);
      this.SentCodeSuccess = true;
      this.toastService.AddSuccessToast('E-mail enviado com sucesso.');
    } catch (error) {
      this.toastService.AddErrorToast('Ocorreu um erro, tente novamente...');
      console.warn('BE Error', error);
    }
    this.LoadingData = false;
  }

  Retry() {
    this.SentCodeSuccess = false;
  }

  async SendNewPassword() {
    if (this.NewPassword === this.NewPasswordConfirmation) {
    this.LoadingData = true;
      try {
        await this.sessionService.ChangePasswordByToken(this.UserEmail, this.NewPassword, this.NewPasswordConfirmation, this.UserToken);
        this.sessionService.Logout();
        this.toastService.AddSuccessToast('Palavra-passe alterada com sucesso.');
        setTimeout(() => {
          const goTo = new NavItem();
          goTo.itemGo = ['home'];
          this.navigationService.NavigateTo(goTo);
        }, 1500);
      } catch (error) {
        this.toastService.AddErrorToast('Ocorreu um erro, tente novamente...');
        console.warn('BE Error', error);
        this.LoadingData = false;
      }
    } else {
      this.toastService.AddWarningToast('As palavras-passe não correspondem.');
    }
  }

  GoBack() {
    const goTo = new NavItem();
    goTo.itemGo = ['login'];
    this.navigationService.NavigateTo(goTo);
  }

  ValidatePassword(code) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return re.test(String(code).toLowerCase());
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

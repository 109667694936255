<div class="location-modal-wrapper">
    <div class="location-wrapper  custom-scrollbar">
        {{ description }}
    </div>
    <div class="location-options-wrapper">
        <app-generic-button [Label]="cancel" (ClickedChange)="close()" [CustomClass]="'cancel'">
        </app-generic-button>
        <app-generic-button [Label]="confirmation" (ClickedChange)="acceptDialog()">
        </app-generic-button>
    </div>
</div>
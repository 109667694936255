import { animate, style, transition, trigger } from '@angular/animations';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
  animations: [
    trigger(
      'ModalAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ transform: 'scale(0)', opacity: 0 }),
            animate('0.3s ease-out', 
                    style({ transform: 'scale(1)', opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ transform: 'scale(1)', opacity: 1 }),
            animate('0.3s ease-in', 
                    style({ transform: 'scale(0)', opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class PopupModalComponent implements OnInit {


  @Input() OpenModal: boolean;
  @Input() Closable = true;
  @Output() OpenModalChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  CloseModal(event?: any) {
    this.OpenModal = false;
    this.OpenModalChange.emit(false);
  }
}

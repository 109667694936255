import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { Menu } from 'src/assets/models/management/menu.model';
import { CartItem } from 'src/assets/models/ui/cart-item.model';
import { OrdersService } from './orders.service';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  ShowShoppingCart: boolean;
  ShowShoppingCartChange: Subject<boolean> = new Subject<boolean>();
  CartItems: CartItem[] = [];
  CartItemsChange: Subject<CartItem[]> = new Subject<CartItem[]>();
  TotalItems = 0;
  TotalItemsChange: Subject<number> = new Subject<number>();
  TotalPrice = 0;
  TotalPriceChange: Subject<number> = new Subject<number>();

  constructor(private orderService: OrdersService) {
    this.ShowShoppingCartChange.subscribe((value) => {
      this.ShowShoppingCart = value;
    });

    this.CartItemsChange.subscribe((value) => {
      this.CartItems = value;
      this.TotalItemsChange.next(this.CartItems.length);
      this.CalculateTotalPrice();
    });

    this.TotalItemsChange.subscribe((value) => {
      this.TotalItems = value;
    });

    this.TotalPriceChange.subscribe((value) => {
      this.TotalPrice = value;
    });

    this.LoadCartFromSave();
   }

   OpenShoppingCart() {
     this.ShowShoppingCartChange.next(true);
   }

   CloseShoppingCart() {
    this.ShowShoppingCartChange.next(false);
  }

  EmptyCart() {
    this.CartItemsChange.next([]);
    this.SaveCartInfo(false);
  }

  AddItem(menuItem: Menu, quantity: number) {
    const cartItem = new CartItem();
    cartItem.id = Guid.create().toString();
    cartItem.menu = JSON.parse(JSON.stringify(menuItem));
    cartItem.qty = quantity;

    this.CartItems.push(cartItem);
    this.CartItemsChange.next(this.CartItems);
    this.SaveCartInfo();
  }

  SaveCartInfo(forceUpdate = true) {
    sessionStorage.setItem('PD-CART-STATE', JSON.stringify(this.CartItems));
    if(forceUpdate) {
      this.CartItemsChange.next(this.CartItems);
    }
  }

  LoadCartFromSave() {
    const cartInCache = sessionStorage.getItem('PD-CART-STATE');

    if(cartInCache) {
      try {
        this.CartItemsChange.next(JSON.parse(cartInCache));
      } catch (error) {
        
      }
    }
  }

  RemoveItem(id) {
    const itemIndex = this.CartItems.findIndex(item => item.id === id);

    if(itemIndex !== null && itemIndex !== undefined) {
      this.CartItems.splice(itemIndex, 1);
      this.CartItemsChange.next(this.CartItems);
    }

    this.SaveCartInfo();
  }

  CalculateTotalPrice() {
    
    let total = 0;
    this.CartItems.forEach(item => {
      if(item.menu.sizeid) {
        const sizePrice = item.menu.sizes.find(size => size.id === item.menu.sizeid);
        total += (sizePrice.priceincrease) * item.qty;
      } else {
        total += (item.menu.price) * item.qty;
      }
    });

    this.TotalPriceChange.next(total);
    console.log('entered', total);
  }

  async CheckOutCart() {
    try {
      await this.orderService.checkOutOrder(this.CartItems, 'Notas', '');
    } catch (error) {
      
    }
  }

  async getAddressDeliveryCost(cartSystem: any) {
    return await this.orderService.getCartFullCost(cartSystem);
  }

}

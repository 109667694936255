<div class="footer-background">
    <div class="footer-wrapper">
        <div class="footer-column">
            <div class="footer-title">
                Redes sociais
            </div>
            <a href="https://www.instagram.com/pedirsemsair/" class="social-network" target="_blank">
                <span class="regular-small-svg white" [inlineSVG]="'../../../assets/media/social/insta.svg'"></span>
                Instagram
            </a>
            <a href="https://www.facebook.com/Pedir-Sem-Sair-347180036348817" class="social-network" target="_blank">
                <span class="regular-small-svg white" [inlineSVG]="'../../../assets/media/social/fb.svg'"></span>
                Facebook
            </a>
        </div>
        <div class="footer-column">
            <div class="footer-title">
                Links úteis
            </div>
            <a (click)="GoTo('privacy-policy')" class="social-network link">
                Politica de privacidade
            </a>
            <a (click)="GoTo('cookie-policy')" class="social-network link" target="_blank">
                Politica de cookies
            </a>
        </div>
        <div class="footer-column">
            <div class="footer-title">
                Fala connosco
            </div>
            <a href="mailto:geral@pedirsemsair.pt" class="social-network link" target="_blank">
                geral@pedirsemsair.pt
            </a>
            <a href="tel:+351920057090" class="social-network link">
                +351 920 057 090
            </a>
        </div>
    </div>
    <div class="footer-bottom-wrapper">
        <p>© 2021 Pedir Sem Sair. Todos os direitos reservados.</p>
    </div>
</div>
<div class="dropdown-modal-wrapper">
    <div class="dropdown-wrapper  custom-scrollbar">
        <div class="dropdown-span-wrapper">
            <span *ngFor="let item of options" [class.selected-option]="selection === item.key" [class.extra]="item.extra"
            (click)="selection = item.key">
                {{ item.value }}
                <label *ngIf="item.extra">
                {{ item.extra }}
                </label>
            </span>
        </div>
    </div>
    <div class="dropdown-options-wrapper">
        <app-generic-button [Label]="'Cancelar'" (ClickedChange)="close()" [CustomClass]="'cancel'">
        </app-generic-button>
        <app-generic-button [Label]="'Continuar'" (ClickedChange)="closeDialog()" [Disabled]="!selection">
        </app-generic-button>
    </div>
</div>
<div *ngIf="ShowMenu" [@TriggerMenu] class="nav-menu-wrapper" (click)="$event.stopPropagation();">
    <div class="close-menu-wrapper" (click)="closeMenu();">
       <span class="arrow down"></span>
    </div>
    <div class="content-wrapper">
        <div class="account-banner-wrapper">
            <img id="bg-profile-img" src="../../../assets/media/img/bg-profile.png" alt="">
            <div class="user-logo-wrapper">
                <img src="../../../assets/media/defaults/default-user.png" alt="">
            </div>
            <div class="user-name-wrapper">
                Menu de utilizador
            </div>
        </div>
        <div class="items-wrapper">
            <div class="item-wrapper" (click)="travelTo(['user', 'account']); $event.stopPropagation();">
                <div class="item-icon">
                    <span class="regular-md-svg" [inlineSVG]="'../../../assets/media/img/personal.svg'"></span>
                </div>
                <div class="item-title">
                    Dados pessoais
                </div>
            </div>
            <div class="item-wrapper" (click)="travelTo(['user', 'addresses']); $event.stopPropagation();">
                <div class="item-icon">
                    <span class="regular-md-svg" [inlineSVG]="'../../../assets/media/img/address.svg'"></span>
                </div>
                <div class="item-title">
                    Moradas
                </div>
            </div>
            <div class="item-wrapper" (click)="travelTo(['user', 'history']); $event.stopPropagation();">
                <div class="item-icon">
                    <span class="regular-md-svg" [inlineSVG]="'../../../assets/media/img/delivery.svg'"></span>
                </div>
                <div class="item-title">
                    Encomendas
                </div>
            </div>
            <div class="item-wrapper" (click)="travelTo(['user', 'notifications']); $event.stopPropagation();">
                <div class="item-icon">
                    <span class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/bell-line.svg'"></span>
                </div>
                <div class="item-title">
                    Notificações
                </div>
            </div>
            <a href="tel:+351920057090">
                <div class="item-wrapper" (click)=" $event.stopPropagation();">
                    <div class="item-icon">
                        <span class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/phone.svg'"></span>
                    </div>
                    <div class="item-title">
                        Ajuda
                    </div>
                </div>
            </a>
            <div class="item-wrapper aligned-center" (click)="travelTo(['logout']); $event.stopPropagation();">
                <div class="item-icon">
                    <span class="regular-small-svg" [inlineSVG]="'../../../assets/media/img/logout.svg'"></span>
                </div>
                <div class="item-title">
                    Logout
                </div>
            </div>
        </div>
    </div>
    <div class="version-space-wrapper">
        v2.0.1
    </div>
</div>
<div class="steps-wrapper">
    <div class="step-wrapper">
        <div class="step-img">
            <img src="../../../assets/media/cards/deliveryboy.png" alt="">
        </div>
        <div class="step-description">
            <h2>Estafetas</h2>
            <p>Queres fazer parte da nossa equipa?
                <br>
            Envia-nos uma mensagem para saber mais!</p>
        </div>
        <div class="step-action">
            <button (click)="GoTo('colaborate')">
                Colaborar
            </button>
        </div>
    </div>
    <div class="step-wrapper">
        <div class="step-img">
            <img src="../../../assets/media/cards/bg2.jpg" alt="">
        </div>
        <div class="step-description">
            <h2>Ser parceiro</h2>
            <p>Regista-te na nossa rede de parceiros, e tem o teu próprio serviço de entrega ao domicilio!</p>
        </div>
        <div class="step-action">
            <button (click)="GoTo('partner')">
                Registar
            </button>
        </div>
    </div>
    <div class="step-wrapper">
        <div class="step-img">
            <img src="../../../assets/media/cards/contact.jpg" alt="">
        </div>
        <div class="step-description">
            <h2>Contactos</h2>
            <p>Queres falar connosco?
                <br><br>
                Preenche o formulário e entraremos em contacto o mais rápido possível.
            </p>
        </div>
        <div class="step-action">
            <button (click)="GoTo('contacts')">
                Contactar
            </button>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NavItem } from 'src/assets/models/ui/nav-item.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public ShowNavMenu: boolean;
  ShowNavMenuChange: Subject<boolean> = new Subject<boolean>();
  private ActiveItem: NavItem;
  private AvailableRoutes = {};
  ActiveItemChange: Subject<NavItem> = new Subject<NavItem>();

  constructor(private router: Router) {
    this.ActiveItemChange.subscribe((navItem) => {
      this.ActiveItem = navItem;
        this.router.navigate(navItem.itemGo);
    });

    this.ShowNavMenuChange.subscribe((value) => {
      this.ShowNavMenu = value
    });
  }

  SetActiveItem(navItem: NavItem) {
    this.ActiveItemChange.next(navItem);
  }

  NavigateTo(navItem: NavItem) {
    this.ActiveItemChange.next(navItem);
  }

  SetAvailableRoutes(availableRoutes) {
    this.AvailableRoutes = availableRoutes
  }

  ConfirmRoute() {

  }

  OpenMenu() {
    this.ShowNavMenuChange.next(true);
  }

  CloseMenu() {
    this.ShowNavMenuChange.next(false);
  }
}

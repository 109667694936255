<app-popup-modal [(OpenModal)]="ShowAddressPopup" (OpenModalChange)="ClosePopup()">
    <div slot="title">
        Selecionar local
    </div>
    <div slot="body">
        <div class="address-popup-wrapper">
            <agm-map class="map-picker" [zoom]="13" [mapTypeId]="'hybrid'"
            [latitude]="City.lat" [longitude]="City.long">
                <ng-container *ngIf="PickedLatitude && PickedLongitude">
                        <agm-marker [latitude]="PickedLatitude" [longitude]="PickedLongitude">
                        </agm-marker>
                </ng-container>
                <ng-container *ngIf="City">
                    <agm-circle [latitude]="City.lat" [strokeColor]="'#fff'" [fillOpacity]="0.1" [strokeWeight]="2" [longitude]="City.long"
                        (circleClick)="SetCoordinates($event)" [radius]="City.maxdeliveryrange">
                    </agm-circle>
                </ng-container>
            </agm-map>
        </div>
    </div>
    <div slot="actions">
        <button [disabled]="!PickedLatitude || !PickedLongitude" class="regular-button green" (click)="ConfirmCoordinates()">
            Guardar localização
        </button>
    </div>
</app-popup-modal>
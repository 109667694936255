import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilsService } from 'src/app/services/utils.service';
import { City } from 'src/assets/models/management/city.model';
import { CategoryType } from 'src/assets/models/management/menu.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';
import { NavItem } from 'src/assets/models/ui/nav-item.model';
import { LocationModalComponent } from '../popup-modal/location-modal/location-modal.component';
import { ScheduleModalComponent } from '../popup-modal/schedule-modal/schedule-modal.component';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

  public AvailableHours: DropdownItem[];
  public maxHour = 24;
  public minuteInterval = 15;
  public DeliveryTime: string;
  public HasLocationEnabled: boolean;

  public LoadingCategory: boolean;

  public Cities: City[];
  public CityOptions: DropdownItem[];
  public CategoryOptions: DropdownItem[];
  public SelectedCityOption: City;
  public CategoryBackground: string;
  public Category: string;

  constructor(private navigationService: NavigationService,
    private shoppingCart: CartService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private modalService: ModalService,
    private locationService: LocationService,
    private supplierService: SuppliersService) { }

  async ngOnInit() {
    this.CalculateAvailableHours();
    this.Cities = await this.supplierService.getCities();
    this.BuildCityOptions(this.Cities);
    this.BuildCategoryOptions(this.locationService.CategoryTypes);
    const result = this.locationService.CategoryTypes.find(category => category.key === this.supplierService.Parameters.category_type);
    if (result) {
      this.CategoryBackground = result.image;
      this.Category = this.supplierService.Parameters.category_type;
    } else if (this.locationService.CategoryTypes.length > 0) {
      this.SetSelectedCategory(this.locationService.CategoryTypes[0].key);
    }
  }

  BuildCategoryOptions(categoryTypes: CategoryType[]) {
    const selectedCity = sessionStorage.getItem('PD-CITY');
    this.CategoryOptions = [];
    categoryTypes.forEach(category => {
      const newCityOption = new DropdownItem();
      newCityOption.key = category.key;
      newCityOption.value = category.name;
      this.CategoryOptions.push(newCityOption);
    });

  }

  CalculateAvailableHours() {
    this.AvailableHours = [];
    const selectedTime = sessionStorage.getItem('PD-TIME');
    const todaysDay = new Date().getDate();
    const weekDay = new Date().getDay();
    const isWeekend = (weekDay === 0 || weekDay === 6 || weekDay === 5) ? true : false;
    const minTime =  isWeekend ? 40 : 20;

    let currentDate = new Date();

    currentDate.setHours(currentDate.getUTCHours() + 1);
    currentDate.setMinutes(currentDate.getUTCMinutes());
    const minDateToAsk = new Date(currentDate);
    minDateToAsk.setMinutes(currentDate.getMinutes() + minTime);

    currentDate.setMinutes(this.minuteInterval);

    const composedDate = new Date(currentDate);
    composedDate.setHours(composedDate.getHours() + 24);
    const possibleDates = [];

    while (currentDate < composedDate) {

      const nextDate = new Date(currentDate);
      nextDate.setMinutes(nextDate.getMinutes() + this.minuteInterval);
      possibleDates.push({ start: new Date(currentDate), end: nextDate });

      currentDate.setMinutes(currentDate.getMinutes() + this.minuteInterval);
    }

    const blackListedHours = ['18:45', '19:15', '19:45', '20:15', '20:45'];
    possibleDates.forEach((interval, index) => {
      if (possibleDates[index + 1]) {
        if (interval.start > minDateToAsk) {
          const todayNumber = interval.start.getDate();
          const hourSliced = ('0' + interval.start.getHours()).slice(-2);
          const minuteSliced = ('0' + interval.start.getMinutes()).slice(-2);
          const timeRole = hourSliced + ':' + minuteSliced;

          const hourSlicedNext = ('0' + interval.end.getHours()).slice(-2);
          const minuteSlicedNext = ('0' + interval.end.getMinutes()).slice(-2);
          const timeRoleNext = hourSlicedNext + ':' + minuteSlicedNext;

          const dateTime = this.utilsService.ConvertDateTime(interval.start, true);
          const intervalAsString = dateTime.date + ' ' + dateTime.time;

          const intervalComposedText = (todayNumber == todaysDay ? 'Entre ' : 'Amanhã entre') + ' as ' + timeRole + ' e ' + timeRoleNext;

          if(isWeekend && blackListedHours.indexOf(dateTime.time) > -1) {

          } else {
            this.AvailableHours.push({ key: intervalAsString, value: intervalComposedText });
          }

          if (selectedTime === intervalAsString) {
            this.DeliveryTime = intervalAsString;
            this.SetDeliveryTime(intervalAsString);
          }
        }
      }
    });
  }

  BuildCityOptions(cityList) {
    const selectedCity = sessionStorage.getItem('PD-CITY');
    this.CityOptions = [];
    cityList.forEach(city => {
      const newCityOption = new DropdownItem();
      newCityOption.key = city.id;
      newCityOption.value = city.city;
      this.CityOptions.push(newCityOption);
      if (city.id.toString() === selectedCity) {
        this.SelectedCityOption = newCityOption.key;
        this.SetSelectedCity(city);
      }
    });

  }

  BuildSessionValues() {
    if (this.DeliveryTime && this.SelectedCityOption) {
      this.Search();
    }
  }

  Search() {
    const goTo = new NavItem();

    goTo.itemGo = ['home', 'suppliers'];
    this.navigationService.NavigateTo(goTo);
  }

  SetSelectedCity(event: City) {
    this.SelectedCityOption = event;
    this.supplierService.SetCity(event.id);
    if (sessionStorage.getItem('PD-CITY') !== event.id.toString()) {
      sessionStorage.setItem('PD-CITY', event.id.toString());
      this.shoppingCart.EmptyCart();
    }
  }

  SetDeliveryTime(event: string) {
    this.supplierService.SetDeliveryHours(event);
    this.DeliveryTime = event;
    if (sessionStorage.getItem('PD-TIME') !== event.toString()) {
      sessionStorage.setItem('PD-TIME', event.toString());
      this.shoppingCart.EmptyCart();
    }
  }

  ShowChangesWarning() {
    if (this.shoppingCart.TotalItems > 0) {
      this.toastService.AddWarningToast('Alterar os parâmetros de pesquisa limpará o seu pedido atual.');
    }
  }

  SetSelectedCategory(event: string) {
    this.supplierService.SetCategoryType(event);
    this.shoppingCart.EmptyCart();

    // this.LoadingCategory = true;
    this.Category = this.supplierService.Parameters.category_type;
    setTimeout(() => {
      this.CategoryBackground = this.locationService.CategoryTypes.find(category => category.key === this.supplierService.Parameters.category_type).image;
      // this.LoadingCategory = false;
    }, 500);
  }

  openPlacePicker() {
    this.modalService.openDialog({component: LocationModalComponent, data: {options: this.Cities, selectedCity: this.SelectedCityOption}}).subscribe(value => {
    if(value.action) {
      this.SetSelectedCity(value.data);
    }      
    });
  }

  openTimePicker() {
    this.modalService.openDialog({component: ScheduleModalComponent, data: {selectedHour: this.DeliveryTime}}).subscribe(value => {
    if(value.action) {
      this.SetDeliveryTime(value.data);
    }      
    });
  }

}

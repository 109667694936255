<div class="contact-form">
    <div class="form-wrapper">
        <div class="field-wrapper">
            <label>Nome *</label>
            <input type="text" [(ngModel)]="UserContact.sendername">
        </div>
    </div>
    <div class="form-wrapper">
        <div class="field-wrapper">
            <label>E-mail *</label>
            <input type="text" [(ngModel)]="UserContact.senderemail">
        </div>
        <div class="field-wrapper">
            <label>Telemóvel (Opcional)</label>
            <input type="text" [(ngModel)]="UserContact.senderphone">
        </div>
    </div>
    <div class="form-wrapper">
        <div class="field-wrapper">
         <label for="">Cidade</label>
         <app-dropdown [Label]="'Selecione uma cidade'" [Items]="CityOptions" (FieldChange)="SetCity($event)"
         [(Field)]="SelectedCity"></app-dropdown>
        </div>
     </div>
    <div class="form-wrapper">
        <div class="field-wrapper">
            <label>Mensagem</label>
            <textarea cols="30" rows="5" [(ngModel)]="UserContact.sendermessage"></textarea>
        </div>
    </div>
    <div class="form-wrapper">
        <app-generic-button 
        [Disabled]="Sending || !UserContact.sendername || !ValidateEmail(UserContact.senderemail) || (UserContact.senderphone && !ValidatePhone(UserContact.senderphone)) || !UserContact.sendercity || !UserContact.sendermessage"
        (ClickedChange)="SendContactForm()"
        [Label]="'Entrar em contacto'"></app-generic-button>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { NavItem } from 'src/assets/models/ui/nav-item.model';
import { ToastType } from 'src/assets/models/ui/toast-message.model';

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent implements OnInit {


  public Number: number;
  public NewNumber: number;
  public VerificationCode: string;
  public Verifying: boolean;

  constructor(
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private toastService: ToastService) { }

  async ngOnInit() {
    try {
    const clientInfo = await this.sessionService.GetUserInfo();
    this.Number = clientInfo.phonenumber;
    this.NewNumber = clientInfo.phonenumber;
    } catch (error) {
      
    }
  }

  async Verify() {
    this.Verifying = true;

    try {
      await this.sessionService.ValidatePhoneNumber(this.NewNumber, this.VerificationCode);
      this.toastService.AddToast(ToastType.Success, 'Validado com sucesso, aguarde...', 1000);
      setTimeout(() => {
        const goTo = new NavItem();
        goTo.itemGo = ['home'];
        if(this.route.snapshot.params['redirect']) {
          goTo.itemGo = [this.route.snapshot.params['redirect']];
        }
        this.navigationService.NavigateTo(goTo);
      }, 1000);
    } catch (error) {
      this.toastService.AddWarningToast('Validação falhou, tente novamente mais tarde...');
    }
    this.Verifying = false;
  }

  async Resend() {
    if(this.ValidatePhone(this.NewNumber)) {
      try {
        await this.sessionService.ResendPhoneNumber(this.Number, this.NewNumber !== this.Number ? this.NewNumber : undefined);
        this.toastService.AddSuccessToast('Um novo código foi enviado para o seu telemóvel, aguarde');
      } catch (error) {
          if(error.status === 400) {
          this.toastService.AddWarningToast('Já enviou um código há menos de cinco minutos, por favor aguarde...');
          }
      }
    } else {
      this.toastService.AddWarningToast('O número que inseriu não é válido');
    }
  }

  ValidatePhone(phone) {
    const re = /^\d{9}$/;
    return re.test(String(phone).toLowerCase());
  }
}

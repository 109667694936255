import { Component, Input, OnInit } from '@angular/core';
import { Card } from 'src/assets/models/ui/card.model';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  @Input() Cards: Card[];

  constructor() { }

  ngOnInit(): void {
    console.log(this.Cards);
    
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Menu } from 'src/assets/models/management/menu.model';

@Component({
  selector: 'app-menus-page',
  templateUrl: './menus-page.component.html',
  styleUrls: ['./menus-page.component.scss']
})
export class MenusPageComponent implements OnInit {

  public supplierId: number;
  public Menus: Menu[];
  public MenuByTypes = {};
  public Loading: boolean;

  constructor(private route: ActivatedRoute, private supplierService: SuppliersService) { }

  async ngOnInit() {
    this.route.params.subscribe(async values => {
      this.supplierId = values['supplier'];
      await this.BuildMenuByTypes();
    });
    // this.supplierId = this.route.params['supplier'];
    // await this.BuildMenuByTypes();
  }

  async BuildMenuByTypes() {
    console.log('enter');
    
    this.Loading = true;
    this.MenuByTypes = {};
    // this.Menus = await this.supplierService.getSupplierMenu(this.supplierId);

    this.Menus.forEach(menu => {
      if(!this.MenuByTypes[menu.menutypename]) {
        this.MenuByTypes[menu.menutypename] = [];
      }

      this.MenuByTypes[menu.menutypename].push(menu);
    });
    this.Loading = false;
  }

}

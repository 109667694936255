import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UserRegister } from 'src/assets/models/management/user.model';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {
  
  public UserProfile: UserRegister;
  public ChangePassword: boolean;
  public Saving: boolean;

  constructor(private sessionService: SessionService,
    private toastService: ToastService) { }

  async ngOnInit() {
    this.UserProfile = await this.sessionService.GetUserInfo();
  }

  TriggerChangePassword() {
    this.ChangePassword = !this.ChangePassword;
  }

  async SaveChanges() {

    if(!this.ChangePassword) {
      this.UserProfile.old_password = null;
      this.UserProfile.password = null;
      this.UserProfile.confirm_password = null;
    } else if(this.ChangePassword 
      && (!this.UserProfile.password || this.UserProfile.password.length < 4 || !this.UserProfile.confirm_password || this.UserProfile.confirm_password.length < 4 || this.UserProfile.password !== this.UserProfile.confirm_password)) {
      this.toastService.AddWarningToast('A palavra-passe e a sua confirmação devem de ser iguais e ter acima de 4 caracteres');
      return;
    }

    this.Saving = true;
    try {
      await this.sessionService.saveUserInfo(this.UserProfile);
      this.UserProfile = await this.sessionService.GetUserInfo();
      this.toastService.AddSuccessToast('Perfil atualizado com sucesso.');
    } catch (error) {
      if(error.status === 403) {
        this.toastService.AddWarningToast('A palavra-passe não corresponde à atual.');
      } else {
        this.toastService.AddErrorToast('Ocorreu um erro ao guardar.');
      }
    }
    setTimeout(() => {
      this.Saving = false;
    }, 250);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { OrdersService } from 'src/app/services/orders.service';
import { SessionService } from 'src/app/services/session.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Card } from 'src/assets/models/ui/card.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  public RecommendedCards: Card[];
  public HasLocation: boolean;

  constructor(private route: ActivatedRoute, private supplierService: SuppliersService) { }

  ngOnInit(): void {
    this.RecommendedCards = [];
    this.RecommendedCards.push({
      id: 'restaurant1',
      title: 'Restaurante Velha alta',
      img: null,
      description: 'Casa das melhores bifanas do norte'
    });
    this.RecommendedCards.push({
      id: 'restaurant2',
      title: 'Casa das pizzas',
      img: null,
      description: 'Melhores pizzas com grande personalização'
    });
    this.RecommendedCards.push({
      id: 'restaurant3',
      title: 'Toca do Lobo',
      img: null,
      description: 'Picanha e feijão preto é connosco.'
    });

    this.route.params.subscribe(params => {
      if(params.type) {
        this.supplierService.SetCategoryType(params.type);
      }
    });

  }

}

import { Injectable } from '@angular/core';
import { Guid } from "guid-typescript";
import { ToastMessage, ToastType } from 'src/assets/models/ui/toast-message.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {


  public ToastMessages: ToastMessage[];
  constructor() {

    this.ToastMessages = [];

   }

   AddToast(messageType: ToastType, message: string, timer = 3000) {
     const newToast = new ToastMessage();
     newToast.type = messageType;
     newToast.message = message;
     newToast.id = Guid.create().toString();
    newToast.timer = timer;
     this.ToastMessages.push(newToast);
   }

   AddSuccessToast(message: string) {
    this.AddToast(ToastType.Success, message)
   }

   AddErrorToast(message: string) {
    this.AddToast(ToastType.Error, message)
   }

   AddWarningToast(message: string) {
    this.AddToast(ToastType.Warning, message)
   }

   GetMessages() {
     return this.ToastMessages;
   }

   DismissMessage(messageKey) {
    const messagePosition = this.ToastMessages.findIndex(message => message.id === messageKey);
    this.ToastMessages.splice(messagePosition, 1);
  }
}

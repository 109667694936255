import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ToastService } from 'src/app/services/toast.service';
import { City } from 'src/assets/models/management/city.model';
import { UserContact } from 'src/assets/models/management/user.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  @Input() ContactType: string;
  public UserContact = new UserContact();
  public Cities: City[];
  public SelectedCity: DropdownItem;
  public CityOptions: DropdownItem[];
  public Sending: boolean;

  constructor(private sessionService: SessionService,
    private toastService: ToastService,
    private navigationService: NavigationService,
    private supplierService: SuppliersService) { }

  async ngOnInit() {
    this.Cities = await this.supplierService.getCities();
    this.BuildCityOptions(this.Cities);
  }

  BuildCityOptions(cityList) {
    this.CityOptions = [];
    cityList.forEach(city => {
      this.CityOptions.push({ key: city.id, value: city.city })
    });
  }

  SetCity(event) {
    this.UserContact.sendercity = event;
  }

  async SendContactForm() {
    try {
      this.Sending = true;
      this.UserContact.sendermessage = `(${this.ContactType}) ` + this.UserContact.sendermessage;
      await this.sessionService.sendContact(this.UserContact);
      this.toastService.AddSuccessToast('Obrigado, em breve entraremos em contacto.');
      setTimeout(() => {
        this.navigationService.NavigateTo({ itemGo: ['home'] });
      }, 1000);
    } catch (error) {
      this.Sending = false;
    }
  }

  ValidateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ValidatePhone(phone) {
    const re = /^\d{9}$/;
    return re.test(String(phone).toLowerCase());
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  
  ConvertDateTime(toConvertDate: string | Date, cropSeconds = false) {
    if(typeof toConvertDate === 'string') {
      toConvertDate = toConvertDate.replace(' ', 'T');
    }
    const toDate = new Date(toConvertDate);
    const date = toDate.getFullYear() + '/'
    + ('0' + (toDate.getMonth()+1)).slice(-2) + '/'
    + ('0' + toDate.getDate()).slice(-2);

    let time = ('0' + toDate.getHours()).slice(-2) + ':'
    + ('0' + toDate.getMinutes()).slice(-2);

    if(!cropSeconds) {
      time += ':' + ('0' + toDate.getSeconds()).slice(-2);
    }

      return { date: date, time: time };
  }

  ToStringDate(toConvertDate, cropSeconds = false) {
    const dateStringObject = this.ConvertDateTime(toConvertDate, cropSeconds);
    let dateString = dateStringObject.date;
    if(dateStringObject.time && toConvertDate.indexOf('T') !== -1) {
      dateString += ' ' + dateStringObject.time;
    }
    return dateString;
  }

  RunnedDate(toConvertDate: string, cropSeconds = false) {
    const Months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const toDate = new Date(toConvertDate);
    const year = toDate.getFullYear();
    const month = Months[toDate.getMonth()];
    const day = ('0' + toDate.getDate()).slice(-2);
    let time = ('0' + toDate.getHours()).slice(-2) + ':'
    + ('0' + toDate.getMinutes()).slice(-2);
    return day + ' de ' + month + ' de ' + year + ' às ' + time;
  }

  DateDifference(Date2, Date1) {

    let diff = (Date2.getTime() - Date1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

  }

}

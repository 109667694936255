import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilsService } from 'src/app/services/utils.service';
import { OrderDetailsModalComponent } from '../order-details-modal/order-details-modal.component';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.scss']
})
export class UserOrderComponent implements OnInit {

  @Input() order: any;
  public Status = ['Nenhum', 'Pendente', 'Em preparação', 'Pronta', 'A caminho', 'Finalizada', 'Cancelada', 'Aceite'];
  public convertedDate: string;
  public dateInDays: number;

  constructor(private utilsService: UtilsService,
    private modalService: ModalService,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.convertDate();
  }


  followOrder(orderId: number) {
    this.navigationService.NavigateTo({ itemGo: ['track', orderId.toString()] })
  }

  convertDate() {
    const dateDifference = this.utilsService.DateDifference(new Date('2022-10-07 23:56'), new Date(this.order.order.created_at));
    const inHours = dateDifference / 60;
    const inDays = Math.floor(inHours / 24);
    this.dateInDays = inDays;
    this.convertedDate = this.utilsService.ToStringDate(this.order.order.created_at, true);
  }

  openOrderDetails() {
    this.modalService.openDialog({ component: OrderDetailsModalComponent, data: { orderDetails: this.order.orderDetails } });
  }

}

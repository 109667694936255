import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { City } from 'src/assets/models/management/city.model';
import { UserAddressList, UserAddress } from 'src/assets/models/management/user.model';
import { DropdownItem } from 'src/assets/models/ui/dropdown-item.model';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {

  public cityOptions: DropdownItem[];
  public addresses: UserAddressList;
  public Saving: boolean;
  public zipCode: string;
  public doorNum: string;
  public newAddress: UserAddress = new UserAddress();
  public selectedCity: City;
  public OpenAddressPopup: boolean;
  public pickedLatitude: number;
  public pickedLongitude: number;
  public detectedLatitude: any;
  public detectedLongitude: any;
  public selectedStep: number = 0;
  public steps = [{ name: 'Informações' }, { name: 'Mapa' }, { name: 'Finalizar' }]

  constructor(private sessionService: SessionService,
    private locationService: LocationService,
    private router: Router,
    private toastService: ToastService) { }
    
  async ngOnInit() {
    this.cityOptions = this.locationService.BuildCities();
    this.addresses = await this.sessionService.GetUserAddresses();
  }

  async saveChanges() {
    this.Saving = true;
    try {
      this.newAddress.lat = this.pickedLatitude;
      this.newAddress.long = this.pickedLongitude;
      this.addresses = await this.sessionService.GetUserAddresses();
      this.addresses.addresses.push(this.newAddress);
      await this.sessionService.saveUserInfo(this.addresses);
      this.toastService.AddSuccessToast('Morada adicionada com sucesso.');
    } catch (error) {
      if(error.status === 403) {
        this.toastService.AddWarningToast('A palavra-passe não corresponde à atual.');
      } else {
        this.toastService.AddErrorToast('Ocorreu um erro ao guardar.');
      }
    }
    this.Saving = false;
    setTimeout(() => {
      this.router.navigate(['user', 'addresses']);
    }, 250);
  }

  setSelectedCity(cityId: number) {
    this.selectedCity = this.locationService.Cities.find(city => city.id === cityId);
  }

  SetCoordinates(mapClick: any) {
    if(mapClick && mapClick.coords && mapClick.coords.lat && mapClick.coords.lng) {
      this.pickedLatitude = parseFloat(mapClick.coords.lat.toFixed(8));
      this.pickedLongitude = parseFloat(mapClick.coords.lng.toFixed(8));
    }
  }

  ValidateZip(zipCode) {
    const re = /^\d{4}-\d{3}?$/;
    return re.test(String(zipCode).toLowerCase());
  }

  async getCoordinatesApprox() {
    const response = await this.sessionService.getCoordinatesByAddress(this.newAddress.streetname + ', ' + this.newAddress.postcode + ' ' + this.selectedCity.city);

    if (response.results && response.results[0] && response.results[0].geometry && response.results[0].geometry.location) {
      this.detectedLatitude = response.results[0].geometry.location.lat;
      this.detectedLongitude = response.results[0].geometry.location.lng;
    }
    console.log(response.results);
  }

  goToAddressList() {
    this.router.navigate(['user', 'addresses'])
  }
}
<div class="topnav-container">
    <app-topnav></app-topnav>
</div>
<div class="body-container">
    <div class="scheduling-container">
        <app-scheduling></app-scheduling>
    </div>
    <div>
        <router-outlet></router-outlet>
    </div>
    <!-- <div class="recommended-container">
            <h1>Recomendados</h1>
            <app-card-list [Cards]="RecommendedCards"></app-card-list>
        </div> -->
    <div class="how-it-works-container">
        <!-- <h1>Junta-te a nós</h1> -->
        <app-how-it-works></app-how-it-works>
    </div>
    <app-footer></app-footer>
</div>
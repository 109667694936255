<div class="supplier-menu">
    <div *ngIf="MenuItem" class="menu-information-wrapper">
        <div class="menu-image-wrapper">
            <img [src]="MenuItem.image ? MenuItem.image : '../../../assets/media/defaults/card.jpg'" (error)="SetDefault($event, MenuItem.image)" alt="">
        </div>
        <div class="menu-information">
            <div class="menu-name">
                {{ MenuItem.name }}
            </div>
            <div class="menu-description">
                {{ MenuItem.description }}
            </div>
        </div>
    </div>
    <div *ngIf="MenuItem.ingredients" class="menu-ingredients-wrapper">
        <label class="menu-notes-label">Ingredientes</label>
        <div class="menu-ingredients">
        {{ MenuItem.ingredients }}
        </div>
    </div>
    <div *ngIf="WriteNotes" class="menu-notes">
        <label class="menu-notes-label">Notas</label>
        <textarea [(ngModel)]="Notes" (ngModelChange)="NoteChanged($event)" cols="30" rows="1"></textarea>
    </div>
    <div *ngIf="MenuItem.size == 1" class="menu-size">
        <label class="menu-notes-label">Opções</label>
        <app-dropdown [Items]="SizeItems" [(Field)]="MenuItem.sizeid" (FieldChange)="EmitSize($event)"></app-dropdown>
    </div>
    <div class="menu-options">
        <div class="menu-qty">
            <app-qty-selector [(Quantity)]="Quantity"
            (QuantityChange)="EmitQuantityChange($event)"
            [MinQuantity]="1"
            [MaxQuantity]="99"></app-qty-selector>
        </div>
        <div class="menu-add-item">
            <span *ngIf="ShowAddLabel && !DeleteMode" [@AddLabel] class="tooltip-add">Adicionado ao carrinho!</span>
            <ng-container *ngIf="!DeleteMode">
                <button (click)="AddItemToCart()" [disabled]="ViewOnly">
                    <span class="regular-mini-svg white" [inlineSVG]="'../../../assets/media/img/buy.svg'"></span>
                    <span class="menu-add-label"> {{ MenuItem.size == 0 ? TrimPrice + ' €' : GetSizePrice() + ' €'}}</span>
                </button>
            </ng-container>
            <ng-container *ngIf="DeleteMode">
                <button (click)="RemoveItemFromCart()" [class.danger]="DeleteMode">
                    <span *ngIf="DeleteMode" class="regular-mini-svg white" [inlineSVG]="'../../../assets/media/img/delete.svg'"></span>
                </button>
            </ng-container>
        </div>
    </div>    
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss']
})
export class GenericButtonComponent implements OnInit {

  @Input() Label: string;
  @Input() Disabled: boolean;
  @Input() IsLoading: boolean;
  @Input() Confirm: boolean;
  @Input() CustomClass: string;
  @Output() ClickedChange: EventEmitter<string> = new EventEmitter();

  public ShowConfirmation = false;

  constructor() { }

  ngOnInit(): void {
    this.ShowConfirmation = false;
  }

  ClickedSend(event: any) {
    this.ClickedChange.emit(event);
    this.ShowConfirmation = false;
  }

  ConfirmClick() {
    this.ShowConfirmation = true;
  }

  CloseConfirm() {
    this.ShowConfirmation = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CartItem } from 'src/assets/models/ui/cart-item.model';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  public Items: CartItem[];
  public Total: number;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
    this.Items = this.cartService.CartItems;
    this.cartService.CartItemsChange.subscribe(items => {
      this.Items = items;
      this.Total = items.length;
    })    
  }

  SaveChanges() {
    this.cartService.SaveCartInfo();
  }

}

<div class="topnav-container">
    <app-topnav [ShowCart]="false"></app-topnav>
</div>
<div class="checkout-container">
    <span class="logo" [inlineSVG]="'../../../assets/media/img/check-out.svg'"></span>
    <div class="info-wrapper">
        <p>A sua encomenda foi registada com sucesso!</p>
        <p>Dentro de momentos receberá um e-mail com a confirmação da sua encomenda.</p>
        <p> Poderá acompanhar o estado da mesma no seu Menu > Encomendas</p>
        <p>ou</p>
        <ng-container *ngIf="OrderId">
            <app-generic-button [Label]="'Acompanhar encomenda'" (ClickedChange)="FollowOrder()">
            </app-generic-button>
        </ng-container>
        <br>
        <p>Para cancelar a sua encomenda deve utilizar a ação de ajuda no <b>Menu > Ajuda</b></p>
    </div>
</div>